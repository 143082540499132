import React from 'react';

const Header = () => {
    return (
        <>
            <section className="wrapper position-relative">
                <div className="container pt-10 pb-17 pt-md-10 text-center">
                    <div className="row">
                        <div className="col-lg-10 mb-10 text-start">
                            <h1 className="fs-16 text-uppercase text-muted mb-1 animation-default animation-delay-700">Join Our Team</h1>
                            <h3 className="display-1 mb-3 animation-default animation-delay-700 fw-light">Join Codevixens and be part of a dynamic team shaping the next generation of tech talents.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light">
                <div className="container pb-8 pb-md-12">
                    <div className="row gx-md-5 gy-5 mt-n18 mt-md-n19">
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h4>Career Growth ✅</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h4>Remote Work ✅</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h4>Rewarding Career ✅</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h4>Flexible Hours ✅</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header;
