import Graduate1 from './assets/img/graduates/g1.jpeg';
import Graduate2 from './assets/img/graduates/g2.jpeg';
import Graduate3 from './assets/img/graduates/g3.jpeg';
import Graduate4 from './assets/img/graduates/g4.png';
import Graduate5 from './assets/img/graduates/g5.jpeg';
import Graduate6 from './assets/img/graduates/g6.jpeg';
import Graduate7 from './assets/img/graduates/g7.jpeg';
import Graduate8 from './assets/img/graduates/g8.jpeg';
import Graduate9 from './assets/img/graduates/g9.jpeg';

import Track1 from './assets/img/tracks/t1.png';
import Track2 from './assets/img/tracks/t2.png';
import Track3 from './assets/img/tracks/t3.png';
import Track4 from './assets/img/tracks/t4.png';
import Track5 from './assets/img/tracks/t5.png';
import Track6 from './assets/img/tracks/t6.png';
import TrackO1 from './assets/img/tracks/to1.jpg';
import TrackO2 from './assets/img/tracks/to2.jpg';
import TrackO3 from './assets/img/tracks/to3.jpg';
import TrackO4 from './assets/img/tracks/to4.jpg';
import TrackO5 from './assets/img/tracks/to5.jpg';
import TrackO6 from './assets/img/tracks/to6.jpg';

import Tutor1 from './assets/img/mentors/m1.jpeg';
import Tutor2 from './assets/img/mentors/m2.jpeg';
import Tutor3 from './assets/img/mentors/m3.jpeg';
import Tutor4 from './assets/img/mentors/m4.jpeg';
import Tutor5 from './assets/img/mentors/m5.jpeg';
import Tutor6 from './assets/img/mentors/m6.jpeg';
import Tutor7 from './assets/img/mentors/m7.jpeg';
import Tutor8 from './assets/img/mentors/m8.jpeg';
import Tutor9 from './assets/img/mentors/m9.png';
import Tutor10 from './assets/img/mentors/m10.jpeg';

//Graduates
export const graduates_info = [
  {
    name: 'Olayimika Omotayo',
    track: 'Digital Marketing',
    img: Graduate1
  },
  {
    name: 'Olanike Oladejo',
    track: 'Content Creation',
    img: Graduate2
  },
  {
    name: 'Moyosade Ademiluyi',
    track: 'Product Design',
    img: Graduate3
  },
  {
    name: 'Roselyn Dennis',
    track: 'Product Design',
    img: Graduate4
  },
  {
    name: 'Comfort Nnamocha',
    track: 'JavaScript',
    img: Graduate5
  },
  {
    name: 'Amina Maidoki',
    track: 'JavaScript',
    img: Graduate6
  },
  {
    name: 'Deanna Jackson',
    track: 'Data Science',
    img: Graduate7
  },
  {
    name: 'Blessing Victor',
    track: 'HTML & CSS',
    img: Graduate8
  },
  {
    name: 'Isi Ikhimiukor',
    track: 'Data Science',
    img: Graduate9
  }
];

//Tracks
export const tracks_info = [
	{
		img: Track1,
		name: "HTML & CSS - Beginner To Advanced",
		bgcolor:"bg-soft-leaf",
		textcolor:"text-leaf",
        overview: "Learn the essentials of web development with HTML and CSS. Create stunning web pages and kickstart your career."
	},
	{
		img: Track1,
		name: "Javascript - Beginner To Advanced",
		bgcolor:"bg-soft-blue",
		textcolor:"text-blue",
        overview: "Master JavaScript from the basics to advanced techniques. Elevate your web development skills."
	},
	{
		img: Track1,
		name: "Reactjs - Beginner To Advanced",
		bgcolor:"bg-soft-leaf",
		textcolor:"text-leaf",
        overview: "Become a ReactJS expert, starting from the basics and progressing to advanced skills. Elevate your web development skills."
	},
	{
		img: Track2,
		name: "Backend Web Development - C#",
		bgcolor:"bg-soft-violet",
		textcolor:"text-violet",
        overview: "Unlock the world of Backend Web Development with C#. From the fundamentals to advanced techniques, empower your web development capabilities."
	},
    {
        img: Track2,
        name: "Backend Web Development - PHP",
        bgcolor:"bg-soft-violet",
        textcolor:"text-violet",
        overview: "Level up your web development skills with PHP. Master backend techniques using PHP from the basics to advanced."
    },
    {
        img: Track2,
        name: "Backend Web Development - Python",
        bgcolor:"bg-soft-violet",
        textcolor:"text-violet",
        overview: "Empower your data-driven journey with Python. Learn backend web development techniques to support data science and analytics, from basics to advanced skills."
    },
    {
        img: Track2,
        name: "Backend Web Development - Nodejs",
        bgcolor:"bg-soft-violet",
        textcolor:"text-violet",
        overview: "Unlock the world of Backend Web Development with C#. From the fundamentals to advanced techniques, empower your web development capabilities."
    },
	{
		img: Track3,
		name: "Technical Writing",
		bgcolor:"bg-soft-grape",
		textcolor:"text-grape",
        overview: "Hone your technical writing skills. Learn to convey complex information clearly and effectively."
	},
	{
		img: Track4,
		name: "Product Design",
		bgcolor:"bg-soft-yellow",
		textcolor:"text-yellow",
        overview: "Unleash your creativity in product design. From concept to creation, develop innovative and user-centric products."
	},
	{
		img: Track5,
		name: "Data Science",
		bgcolor:"bg-soft-fuchsia",
		textcolor:"text-fuchsia",
        overview: "Dive into the world of data science. Gain expertise in data analysis, machine learning, and more."
	},
	{
		img: Track6,
		name: "Product Management",
		bgcolor:"bg-soft-aqua",
		textcolor:"text-aqua",
        overview: "Become a product management expert. Learn how to develop, launch, and manage successful products."
	},
	{
		img: Track6,
		name: "Data Analytics",
		bgcolor:"bg-soft-violet",
		textcolor:"text-violet",
        overview: "Master data analysis techniques. Learn to turn data into valuable insights and drive informed decisions."
	}
];

//Tracks Overviews
export const tracks_details = [
  {
    img: TrackO1,
    track: 'Frontend Developement',
    overview:
      'You’ll learn learn the fundaments of Frontend Web Development with HTML, CSS and JavaScript along with building an interesting portfolio as a frontend developer.'
  },
  {
    img: TrackO2,
    track: 'Backend Developement',
    overview:
      'Go from zero knowledge to being able to build, develop and deploy back-end applications following best practices using NodeJS.'
  },
  {
    img: TrackO3,
    track: 'Technical Writing',
    overview:
      'You’ll learn learn the fundaments of Frontend Web Development with HTML, CSS and JavaScript along with building an interesting portfolio as a frontend developer.'
  },
  {
    img: TrackO4,
    track: 'Product Design',
    overview:
      'Learn what it takes to grow from a junior designer to an experienced designer along with developing the skills to create user experiences for products.'
  },
  {
    img: TrackO5,
    track: 'Data Science',
    overview:
      'You’ll learn learn the fundaments of Frontend Web Development with HTML, CSS and JavaScript along with building an interesting portfolio as a frontend developer.'
  },
  {
    img: TrackO6,
    track: 'Product Management',
    overview:
      'Learn the fundamentals of product management and the best way to get started as a product manager.'
  }
];

//Traning features
export const training_features = [
  {
    icon: 'desktop',
    title: 'Learning Methods',
    features: [
      'Live classes',
      'Video recordings of every live class',
      'Assessments and group projects',
      'Real-time mentoring'
    ]
  },
  {
    icon: 'users-alt',
    title: 'Lectures',
    features: [
      'Weekly classes (2 times a week)',
      'Duration of 2 hours per class',
      'Designated day in the week for reviewing assessments',
      'Get real-time feedback'
    ]
  },
  {
    icon: 'briefcase-alt',
    title: 'Examination',
    features: ['Work on projects', 'Project Demo', 'Project Review']
  }
];

//Tutors
export const tutors_info = [
  {
    name: 'Sakirat Kehinde Usman',
    track: 'Web Development',
    img: Tutor1
  },
  {
    name: 'Obaro Akpotilli',
    track: 'Digital Marketing',
    img: Tutor2
  },
  {
    name: 'Bamgboye Ibukunoluwa',
    track: 'Web Development',
    img: Tutor3
  },
  {
    name: 'Eronmosele Emmanuel',
    track: 'Product Design',
    img: Tutor4
  },
  {
    name: 'Prince Kuro',
    track: 'Content Creation',
    img: Tutor5
  },
  {
    name: 'Nkoye Okoli',
    track: 'Graphics Design',
    img: Tutor6
  },
  {
    name: 'Chiamaka Ezemba',
    track: 'Web Development',
    img: Tutor7
  },
  {
    name: 'Jennifer Prebor',
    track: 'Web Development',
    img: Tutor8
  },
  {
    name: 'Tiwanijesu Sulaiman',
    track: 'Web Development',
    img: Tutor9
  },
  {
    name: 'Anjola Onifade',
    track: 'Web Development',
    img: Tutor10
  }
];

//Why learning how to code
export const why = [
  {
    icon: 'phone',
    title: 'Place to network',
    description:
      'As a developer, you’ll have the chance to meet people with whom you share a similar thought pattern and people with whom you can work together to bring your ideas to life.'
  },
  {
    icon: 'chart',
    title: 'Mentorship',
    description:
      'Everyone needs mentoring. You may have great ideas but you still need the right guidance and support to make them happen.'
  },
  {
    icon: 'laptop',
    title: 'Opportunity to sell yourself',
    description:
      'In giving to your tech community, you’ll be making a name for yourself, impacting people who may recommend you for one or more deals.'
  }
];

//faqs features
export const faqs = [
  {
    icon: 'chart',
    quest: 'Who Can Join Our Community?',
    ans: 'Females from all over the world, no country is exempted'
  },
  {
    icon: 'chart',
    quest: 'Do we accept males into our community?',
    ans: 'Sadly, No. Codevixens Academy is a community built strictly for females.'
  }
  // {
  // 	icon: "chart",
  // 	quest: "How can i manage my Account?",
  // 	ans: "Massa vitae tortor condimentum lacinia quis. Commodo odio aenean sed adipiscing diam. Odio ut sem nulla pharetra diam. Dolor sit amet consectetur adipiscing elit duis tristique sollicitudin nibh.",
  // },
  // {
  // 	icon: "chart",
  // 	quest: "Is my credit card information secure?",
  // 	ans: "Massa vitae tortor condimentum lacinia quis. Commodo odio aenean sed adipiscing diam. Odio ut sem nulla pharetra diam. Dolor sit amet consectetur adipiscing elit duis tristique sollicitudin nibh.",
  // },
];
