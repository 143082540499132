import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const DataEngineeringSchool = () => {
	return (
		<div className="container">
			{/* Introduction to the page */}
			<section className="mb-4">
				<div className="text-center py-4 h1">
					<span
						className="typer text-primary my-4"
						data-delay="60"
						data-words="Data Engineering School"
					></span>
					<span className="cursor text-primary" data-owner="typer"></span>
				</div>
				<p className="mx-2">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
					scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
				</p>
			</section>
			{/* Courses Available */}
			<section>
				<div className="container">
					<h2 className="mb-4">Available Courses</h2>
					<div className="row">
						<div className="col-md-3">
							<div className="card shadow bg-soft-primary mb-4">
								<div className="card-body">
									<span
										className="badge bg-pale-violet text-violet rounded-pill mb-2 d-inline-block text-wrap"
										aria-label="Beginner level, 2 weeks duration"
									>
										Beginner <b>[2 Weeks]</b>
									</span>
									<h3>Introduction to Data Engineering</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
										facilisis.
									</p>
								</div>
								<section className="mb-4 text-center">
									<div>
										<a
											href="/academy-program/schools"
											className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
										>
											Learn More
										</a>
									</div>
								</section>
							</div>
						</div>
						<div className="col-md-3">
							<div className="card shadow bg-soft-navy mb-4">
								<div className="card-body">
									<span
										className="badge bg-pale-violet text-violet rounded-pill mb-2 d-inline-block text-wrap"
										aria-label="Intermediate level, 4 weeks duration"
									>
										Intermediate <b>[4 Weeks]</b>
									</span>
									<h3>Advanced Data Pipelines</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
										facilisis.
									</p>
								</div>
								<section className="mb-4 text-center">
									<div>
										<a
											href="/academy-program/schools"
											className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
										>
											Learn More
										</a>
									</div>
								</section>
							</div>
						</div>
						<div className="col-md-3">
							<div className="card shadow bg-soft-blue mb-4">
								<div className="card-body">
									<span
										className="badge bg-pale-violet text-violet rounded-pill mb-2 d-inline-block text-wrap"
										aria-label="Advanced level, 7 weeks duration"
									>
										Advanced <b>[7 Weeks]</b>
									</span>
									<h3>Big Data Technologies</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
										facilisis.
									</p>
								</div>
								<section className="mb-4 text-center">
									<div>
										<a
											href="/academy-program/schools"
											className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
										>
											Learn More
										</a>
									</div>
								</section>
							</div>
						</div>
						<div className="col-md-3">
							<div className="card shadow bg-soft-aqua mb-4">
								<div className="card-body">
									<span
										className="badge bg-pale-violet text-violet rounded-pill mb-2 d-inline-block text-wrap"
										aria-label="Beginner level, 2 weeks duration"
									>
										Beginner <b>[2 Weeks]</b>
									</span>
									<h3>Data Warehousing and ETL</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
										facilisis.
									</p>
								</div>
								<section className="mb-4 text-center">
									<div>
										<a
											href="/academy-program/schools"
											className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
										>
											Learn More
										</a>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Enrollment details */}
			<section className="wrapper bg-light">
				<div className="container py-10 py-md-12">
					<h2>How to Apply</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
						scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
					</p>
					<div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line">
						<div className="col-md-3 col-lg-3">
							<span className="icon btn btn-circle btn-lg btn-soft-navy pe-none mb-4">
								<span className="number">01</span>
							</span>
							<h4 className="mb-1">Complete the online application</h4>
							<p className="mb-0">
								Nulla vitae elit libero elit non porta gravida eget metus cras.
								Aenean eu leo quam. Pellentesque ornare.
							</p>
						</div>
						<div className="col-md-3 col-lg-3">
							<span className="icon btn btn-circle btn-lg btn-navy pe-none mb-4">
								<span className="number">02</span>
							</span>
							<h4 className="mb-1">Submit your academic transcripts</h4>
							<p className="mb-0">
								Vestibulum id ligula porta felis euismod semper. Sed posuere
								consectetur est at lobortis.
							</p>
						</div>
						<div className="col-md-3 col-lg-3">
							<span className="icon btn btn-circle btn-lg btn-soft-navy pe-none mb-4">
								<span className="number">03</span>
							</span>
							<h4 className="mb-1">Attend an interview</h4>
							<p className="mb-0">
								Integer posuere erat a ante venenatis dapibus posuere velit
								aliquet. Nulla vitae elit libero.
							</p>
						</div>
						<div className="col-md-3 col-lg-3">
							<span className="icon btn btn-circle btn-lg btn-soft-navy pe-none mb-4">
								<span className="number">04</span>
							</span>
							<h4 className="mb-1">Wait for acceptance email</h4>
							<p className="mb-0">
								Integer posuere erat, consectetur adipiscing elit. Fusce
								dapibus, tellus ac cursus commodo.
							</p>
						</div>
					</div>
				</div>
			</section>
			{/* Time period for each course/school */}
			<div className="container">
				<div className="row mt-4 gx-md-6 gy-8 ">
					<div className="col-md-6 mb-4">
						<section>
							<h2 className="mb-4">Course Duration</h2>
							<p className="text-left mt-2">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
								scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
							</p>
							<div className="item col-md-12">
								<figure>
									<img
										src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603200/website/stem-a-girl-main_suaon4.jpg"
										alt="students"
										className="img-fluid"
									/>
								</figure>
							</div>
						</section>
					</div>
					<div className="col-md-6 mb-4">
						<section>
							<h2 className="mb-4">Batch Information</h2>
							<p className="text-left mt-2">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
								scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
							</p>
							<div className="item col-md-12">
								<figure>
									<img
										src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603187/website/home-page_h07lpq.jpg"
										alt="students"
									/>
								</figure>
							</div>
						</section>
					</div>
				</div>
			</div>
			{/* The levels for each course */}

			<section>
				<div className="container mt-12">
					<h2>Levels</h2>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
						scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
						scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
					</p>
				</div>

				<div className="accordion accordion-wrapper pb-12" id="accordionExample">
					<div className="card accordion-item">
						<div className="card-header" id="headingOne">
							<button
								className="collapsed"
								data-bs-toggle="collapse"
								data-bs-target="#collapseOne"
								aria-expanded="true"
								aria-controls="collapseOne"
							>
								Beginner
							</button>
						</div>
						<div
							id="collapseOne"
							className="collapsed"
							aria-labelledby="headingOne"
							data-bs-parent="#accordionExample"
						>
							<div className="card-body">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
									facilisis.
								</p>
							</div>
						</div>
					</div>

					<div className="card accordion-item">
						<div className="card-header" id="headingTwo">
							<button
								className="collapsed"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTwo"
								// aria-expanded="false"
								aria-controls="collapseTwo"
							>
								Intermediate
							</button>
						</div>
						<div
							id="collapseTwo"
							className="accordion-collapse collapse show"
							aria-labelledby="headingTwo"
							data-bs-parent="#accordionExample"
						>
							<div className="card-body">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
									facilisis.
								</p>
							</div>
						</div>
					</div>

					<div className="card  accordion-item">
						<div className="card-header" id="headingThree">
							<button
								className="collapsed"
								data-bs-toggle="collapse"
								data-bs-target="#collapseThree"
								aria-expanded="false"
								aria-controls="collapseThree"
							>
								Expert
							</button>
						</div>
						<div
							id="collapseThree"
							className="accordion-collapse collapse"
							aria-labelledby="headingThree"
							data-bs-parent="#accordionExample"
						>
							<div className="card-body">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
									Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
									facilisis.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default DataEngineeringSchool;
