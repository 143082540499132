import React from "react";
import {Card} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ReviewPlayer from "../../../players/review-player";

const Faqs = () => {
    const faqData = [
        {
            question: 'Do you offer an installment payment plan for the tuition fee if I cannot pay at once?',
            answer:
                'Yes, we offer an installment payment plan to make it easier for you to manage the tuition fee. Please contact our support team for more details on how to set up your plan.',
  
        },
        {
            question: 'Are all the sessions online, or is there an in-person option? ',
            answer:
                'Currently, all our sessions are conducted online to ensure accessibility and convenience for all participants.',

        },
        {
            question: ' Can beginners enroll in the CloudDevOps School? ',
            answer: 'Absolutely! Our CloudDevOps School is designed to accommodate beginners. We provide foundational courses to help you get started and build your skills.',

           
        },
        {
            question: 'Will I get a recommendation letter for job applications after completing a course? ', 
            answer:
                '  Yes, upon your request after successfully completing a course, we provide a recommendation letter to assist you in your job applications.',
        },    
      ]
        

    return (
        <section className="wrapper mb-15">
            <div className="container py-14 pt-md-16 pt-lg-0 pb-md-10">
                <div className="row">
                    <div className="col-xl-10 col-12 mx-auto">
                        <h2 className="fs-15 text-uppercase text-muted mb-3 text-center">FAQ</h2>
                        <h3 className="display-6 mb-10 px-lg-12 px-xl-15 text-center">We've compiled a list of frequently asked questions to provide you with instant answers and insights into our educational programs.</h3>
                        <div className="row">
                            <div className="col-lg-9 col-12 mx-auto">
                                <Accordion id="accordion-1" className="accordion-wrapper">
                                    {faqData.map((faq, index) => (
                                        <Accordion.Item className="card" key={index} eventKey={String(index)}>
                                            <Card.Header>
                                                <Accordion.Button bsPrefix="nil">{faq.question}</Accordion.Button>
                                            </Card.Header>
                                            <Accordion.Body className="card-body">
                                                <p>{faq.answer}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs;
