import React from 'react';
import HeroForSchool from './includes/Hero';
import SchoolFeatures from './includes/Features';
import MoreDetails from './includes/MoreDetails';
import SchoolFaq from './includes/Faq';

const AcademySchools = () => {
    return (
        <>
            <HeroForSchool />
            <MoreDetails />
            <SchoolFaq />
            <SchoolFeatures />
        </>
    );
};

export default AcademySchools;
