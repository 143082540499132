import React from 'react';
import Hero from './includes/Hero';
import Reviews from './includes/Reviews';
import OurPartners from './includes/OurPartners';
import OurInitiatives from './includes/OurInitiatives';
import JoinUs from "./includes/JoinUs";
import Faqs from "./includes/Faqs";

const Home = () => {
    return (
        <div id='homepage'>
            <Hero />
            <OurPartners />
            <OurInitiatives />
            <Reviews />
            <Faqs />
            {/* <JoinUs /> */}
        </div>
    );
};

export default Home;
