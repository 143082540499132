import React, { useContext } from 'react';
import { ModalContext } from '../../../../utils/contexts';
import Close from '../../../../assets/img/svg/close.svg';

function EventRegistration() {
    const { openModal, setOpenModal } = useContext(ModalContext);
    const closeModal = () => {
        document.body.classList.remove('modal-open');
        setOpenModal(false);
    };

    return (
        <>
            {
                openModal &&
                <div className="modal-background">
                    <div className="card col-12 col-md-10 col-xl-5 mobile-height">
                        <div className="card-body p-0 position-relative">
                            <div className="close-button position-absolute mt-1 me-3 end-0 bg-pale-ash rounded-circle w-9 h-9 d-flex justify-content-center align-items-center" onClick={() => closeModal()}>
                                <img src={Close} alt="close icon" />
                            </div>
                            <div>
                                <div className="p-3 border-bottom form-header">
                                    <h5 className='text-center mb-0'> Checkout </h5>
                                </div>
                                <div className="py-5 px-5 px-md-10">
                                    <p>Fill the following input fields to register for this event</p>
                                    <div className="d-flex justify-content-between mb-4">
                                        <div className="col-md-6">
                                            <div className='pe-2'>
                                                <label htmlFor="firstName"> First name<span className='text-red'>*</span> </label>
                                                <input id="firstName" type="text" className="form-control border" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='ps-2'>
                                                <label htmlFor="lastName"> Last name<span className='text-red'>*</span> </label>
                                                <input id="lastName" type="text" className="form-control border" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label htmlFor="emailAddress"> Email address<span className='text-red'>*</span> </label>
                                            <input id="emailAddress" type="text" className="form-control border" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="confirmEmailAddress"> Confirm email<span className='text-red'>*</span></label>
                                            <input id="confirmEmailAddress" type="text" className="form-control border" />
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault"> Keep me updated on more events and news. </label>
                                    </div>
                                    <button type="button" className='btn-primary btn mx-auto my-5'> Register </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default EventRegistration;