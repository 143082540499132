import React from 'react';

const CloudDevOpsSchool = () => {
    return (
        <div className="container">

            {/* Introduction to the page */}
            <section className="mb-4">
                <div className="mt-10 row mb-4">
                    <div className="col-lg-7">
                        <h2 className="mb-3 h6">Welcome to the Codevixens Academy CloudDevOps School 🎉</h2>
                        <h1 className="mb-6 display-3 lh-xxs">We have considered our solutions to
                            <span className="underline-3 style-1 primary">
                                <em>assist</em>
                            </span> every stage of your growth.
                        </h1>
                        {/* <div className="d-flex mb-4" data-cues="zoomIn" data-group="page-title-buttons" data-delay="900">
                            <span><a href="#" className="btn btn-sm btn-dark me-sm-2 fs-15">Explore Now</a></span>
                        </div> */}
                    </div>
                    <div className="col-xl-5 d-flex align-items-center justify-content-center"><div className=""><div><h3 className="h4 mb-0 mb-lg-0">Academic Resources</h3><div className="row gx-3 pt-2"><div className="col-md-6"><div className="mb-2"><a className="btn btn-outline-navy btn-sm w-100" href="/admissions-process">Admission Process</a></div><div><a className="btn btn-outline-navy btn-sm w-100" href="/admissions-policy">Admission Policy</a></div></div><div className="col-md-6"><div className="mb-2"><a className="btn btn-outline-navy btn-sm w-100" href="academic-program.html">Financial Assistance</a></div><div><a className="btn btn-outline-navy btn-sm w-100" href="academic-program.html">Academic Calendar</a></div></div></div></div></div></div>
                </div>
                <p >
                    At Codevixens Academy, we’re dedicated to equipping you with the skills and knowledge needed to excel in the rapidly evolving field of tech. Our specialized courses are designed to meet the demands of the industry, providing you with a solid foundation and advanced techniques to succeed in your career.
                </p>
                <div className="row mt-5 gx-md-6 gy-6 mb-9"><div className="item col-md-4"><figure className="hover-scale rounded cursor-dark"><a data-gallery="project-1" data-glightbox="" href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603852/website/IMG_1239_lfafot.jpg"><img alt="" src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603852/website/IMG_1239_lfafot.jpg" /></a></figure></div><div className="item col-md-4"><figure className="hover-scale rounded cursor-dark"><a data-gallery="project-1" data-glightbox="" href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603200/website/stem-a-girl-main_suaon4.jpg"><img alt="" src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603200/website/stem-a-girl-main_suaon4.jpg" /></a></figure></div><div className="item col-md-4"><figure className="hover-scale rounded cursor-dark"><a data-gallery="project-1" data-glightbox="" href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697616275/website/IMG_1120_pmspfo.jpg"><img alt="" src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697616275/website/IMG_1120_pmspfo.jpg" /></a></figure></div></div>
                {/* Enrollment process */}
                <section className="wrapper bg-light">
                    <div className="container pb-10 pb-md-12">
                        <h2>How to Apply</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                            scelerisque leo nec urna fermentum, a tincidunt lorem facilisis.
                        </p>
                        <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line">
                            <div className="col-md-3 col-lg-3">
                                <span className="icon btn btn-circle btn-lg btn-navy pe-none mb-4">
                                    <span className="number">01</span>
                                </span>
                                <h4 className="mb-1">Complete the online application</h4>
                                <p className="mb-0">
                                    Nulla vitae elit libero elit non porta gravida eget metus cras.
                                    Aenean eu leo quam. Pellentesque ornare.
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <span className="icon btn btn-circle btn-lg btn-soft-navy pe-none mb-4">
                                    <span className="number">02</span>
                                </span>
                                <h4 className="mb-1">Submit your academic transcripts</h4>
                                <p className="mb-0">
                                    Vestibulum id ligula porta felis euismod semper. Sed posuere
                                    consectetur est at lobortis.
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <span className="icon btn btn-circle btn-lg btn-soft-navy pe-none mb-4">
                                    <span className="number">03</span>
                                </span>
                                <h4 className="mb-1">Attend an interview</h4>
                                <p className="mb-0">
                                    Integer posuere erat a ante venenatis dapibus posuere velit
                                    aliquet. Nulla vitae elit libero.
                                </p>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <span className="icon btn btn-circle btn-lg btn-soft-navy pe-none mb-4">
                                    <span className="number">04</span>
                                </span>
                                <h4 className="mb-1">Wait for acceptance email</h4>
                                <p className="mb-0">
                                    Integer posuere erat, consectetur adipiscing elit. Fusce
                                    dapibus, tellus ac cursus commodo.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Courses Available */}
                <section className='mb-10'>
                    <h2 className="mb-1">Beginner Courses</h2>
                    <p className='mb-4'>For students with little to no experience, focusing on foundational skills.</p>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-yellow">
                                <div className="card-body">
                                    <span
                                        className="badge bg-pale-yellow text-yellow mb-2 rounded-0"
                                        aria-label="Beginner Course, 2 weeks duration"
                                    >
                                        Beginner Course
                                    </span>
                                    <h3>Intro to DevOps Engineering</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-green">
                                <div className="card-body">
                                    <span
                                        className="badge bg-pale-green text-green rounded-0 mb-2"
                                        aria-label="Intermediate level, 4 weeks duration"
                                    >
                                        Beginner Course
                                    </span>
                                    <h4>Azure Fundamentals</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-grape">
                                <div className="card-body">
                                    <span
                                        className="badge bg-pale-grape text-grape rounded-0 mb-2"
                                        aria-label="Advanced level, 7 weeks duration"
                                    >
                                        Beginner Course
                                    </span>
                                    <h4>Google Cloud Basics</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-fuchsia">
                                <div className="card-body">
                                    <span className="badge bg-pale-fuchsia text-fuchsia rounded-0 mb-2" aria-label="Beginner Course, 2 weeks duration">
                                        Beginner Course
                                    </span>
                                    <h4>Intro to Cloud Infrastructure</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <h2 className="mb-1">Advanced/Specialized Courses</h2>
                    <p className='mb-4'>For students with some experience or those looking to deepen their expertise in a particular area.</p>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-navy">
                                <div className="card-body">
                                    <span
                                        className="badge bg-pale-yellow text-yellow mb-2 rounded-0"
                                        aria-label="Beginner Course, 2 weeks duration"
                                    >
                                        Beginner Course
                                    </span>
                                    <h3>Azure DevOps Engineering</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-navy">
                                <div className="card-body">
                                    <span
                                        className="badge bg-pale-green text-green rounded-0 mb-2"
                                        aria-label="Intermediate level, 4 weeks duration"
                                    >
                                        Beginner Course
                                    </span>
                                    <h4>Advanced CI/CD Pipelines</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-navy">
                                <div className="card-body">
                                    <span
                                        className="badge bg-pale-grape text-grape rounded-0 mb-2"
                                        aria-label="Advanced level, 7 weeks duration"
                                    >
                                        Beginner Course
                                    </span>
                                    <h4>Introduction to Infrastructure as Code (Terraform)</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card shadow-lg card-border-top border-navy">
                                <div className="card-body">
                                    <span className="badge bg-pale-fuchsia text-fuchsia rounded-0 mb-2" aria-label="Beginner Course, 2 weeks duration">
                                        Beginner Course
                                    </span>
                                    <h4>IaC with AWS CloudFormation</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nullam scelerisque leo nec urna fermentum, a tincidunt lorem
                                        facilisis.
                                    </p>
                                    <div>
                                        <a
                                            href="/academy-program/schools"
                                            className="btn btn-outline-navy btn-sm  justify-content mb-2 me-2"
                                        >
                                            Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row mt-10 pt-10">
                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 mx-auto text-center">
                        <h2 className="fs-16 text-uppercase text-primary mb-3">Why Choose Sandbox?</h2>
                        <h3 className="display-5 mb-5">Who Should Enroll in Our CloudDevOps School?</h3>
                    </div>
                </div>
                <ul className="nav nav-tabs nav-tabs-basic" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link px-3 active" data-bs-toggle="tab" href="#tab2-1" aria-selected="true" role="tab">
                            <div><h3 className="mb-2 fs-md fs-18">Frontend Engineers</h3></div>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" href="#tab2-2" aria-selected="false" role="tab" tabindex="-1">
                            <div><h3 className="mb-2 fs-18">Backend Engineers</h3></div>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" href="#tab2-3" aria-selected="false" role="tab" tabindex="-1">
                            <div><h3 className="mb-2 fs-18">Technical Writers</h3></div>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-bs-toggle="tab" href="#tab2-4" aria-selected="false" role="tab" tabindex="-1">
                            <div><h3 className="mb-2 fs-18">Other Tech Professionals</h3></div>
                        </a>
                    </li>
                </ul>
                <div className="tab-content mt-6 mt-lg-8 mb-md-9">
                    <div className="tab-pane fade active show" id="tab2-1" role="tabpanel">
                        <div className="mt-6">
                            <p>It’s no news that the field of frontend engineering is heavily saturated. With so many people entering tech through HTML &amp; CSS, it’s become a highly competitive space. As the industry evolves and the demand for advanced skills grows, staying ahead requires continuous upskilling. This is particularly important given the recent wave of layoffs in tech, both locally and globally.</p><p>By gaining expertise in DevOps and cloud technologies, frontend engineers can significantly enhance their skill set. Understanding how to host frontend applications, implement CI/CD pipelines, and utilize cloud services is no longer a luxury—it’s a necessity. These skills not only make you more versatile but also open up new career opportunities, ensuring you remain competitive and valuable in the job market.</p>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tab2-2" role="tabpanel">
                        <div className="mt-6">
                            <p>For backend engineers, understanding DevOps and cloud services is crucial for career growth. The backend development field demands a strong grasp of server infrastructure and deployment processes. By learning DevOps practices and cloud computing, backend engineers can streamline development workflows, improve deployment efficiency, and enhance system reliability.</p>
                            <p>As organizations increasingly adopt cloud-based solutions, backend engineers with cloud expertise are in high demand. Knowledge of how to deploy and manage applications in the cloud, automate infrastructure, and ensure robust security practices is essential. This expertise not only positions you for advanced roles but also equips you to tackle complex challenges in modern software development.</p>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tab2-3" role="tabpanel">
                        <div className="mt-6">
                            <h2 className="mb-3">Technical Writers</h2>
                            <p>The field of technical writing is vast, and for those in tech-focused companies, understanding DevOps and cloud technologies is becoming increasingly important. Technical writers often need to document DevOps-related software, tools, and internal processes. In fact, there is a growing demand for freelance technical writers with extensive knowledge of DevOps, cloud computing, Kubernetes, and related technologies. By gaining these skills, technical writers can produce more accurate and comprehensive documentation, making them invaluable assets to their teams.</p>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tab2-4" role="tabpanel">
                        <div className='mt-6'>
                            <h2 className="mb-3">Other Tech Professionals</h2>
                            <p>The field of technical writing is vast, and for those in tech-focused companies, understanding DevOps and cloud technologies is becoming increasingly important. Technical writers often need to document DevOps-related software, tools, and internal processes. In fact, there is a growing demand for freelance technical writers with extensive knowledge of DevOps, cloud computing, Kubernetes, and related technologies. By gaining these skills, technical writers can produce more accurate and comprehensive documentation, making them invaluable assets to their teams.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                <h3 className="lead fs-md fw-bold mt-10">Program Duration &amp; Batches</h3>
                <p className="mb-4">Each course runs for 7 weeks, with a combination of live sessions, hands-on projects, and assessments.&nbsp;Our courses are offered in batches to accommodate different schedules and preferences. Each batch is designed to provide a focused learning environment with a cohort of peers who share similar goals.</p>
                <table className="table table-bordered">
                    <thead>
                        <tr className="bg-pale-primary">

                            <th scope="col" colSpan="2">2024 - Batch A</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                            <td>Start Date:&nbsp;</td>
                            <td>9th of September 2024</td>

                        </tr>
                        <tr>

                            <td>End Date:&nbsp;</td>
                            <td>21st of October 2024</td>

                        </tr>
                        <tr>

                            <td>Application Deadline:&nbsp;</td>
                            <td>3rd of September 2024</td>
                        </tr><tr>

                            <td>Batch Details:</td>
                            <td>
                                <ul className="bullet-bg bullet-soft-navy mb-0"><li>This inaugural batch will be our first cohort under the new structure and will include students across all the courses we offer.</li><li>You will have the opportunity to start your learning journey with a group of like-minded individuals who are equally passionate about advancing their skills in tech.</li></ul></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CloudDevOpsSchool;
