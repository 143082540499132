import React from 'react';
import Hero from './includes/Hero';
import WhoCanJoin from './includes/WhoCanJoin';

const Community = () => {
    return (
        <section className="wrapper">
            <Hero />
            <WhoCanJoin />
        </section>
    );
};

export default Community;