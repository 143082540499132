const UpcomingBatches = () => {
    return (
        <section className="container pb-5">
            <div>
                <h3 className="lead fs-md fw-bold mt-10">Program Duration &amp; Batches</h3>
                <p className="mb-4">Each course runs for 7 weeks, with a combination of live sessions, hands-on projects, and assessments.&nbsp;Our courses are offered in batches to accommodate different schedules and preferences. Each batch is designed to provide a focused learning environment with a cohort of peers who share similar goals.</p>

                <h3 className="lead fs-md fw-bold mt-7">Upcoming Cohorts</h3>
                <p className="mb-4">Join the Next Academy Cohort</p>
                <table className="table table-bordered">
                    <thead>
                        <tr className="bg-pale-primary">
                            <th scope="col" colSpan="2">2024 - Batch A</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-2">Start Date:&nbsp;</td>
                            <td className="py-2">Monday, 13th of January 2025</td>
                        </tr>
                        <tr>
                            <td className="py-2">End Date:&nbsp;</td>
                            <td className="py-2">Friday, 14th of February 2025</td>
                        </tr>
                        <tr>
                            <td className="py-2">Application Deadline:&nbsp;</td>
                            <td className="py-2">Friday, 14th of February 2025</td>
                        </tr>
                        <tr>
                            <td className="py-2">Limited Slots:</td>
                            <td className="py-2">
                                <button className='btn btn-sm btn-gray rounded' disabled>Applications Opening Soon</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default UpcomingBatches;