
const Introduction = () => {
    return (
        <section className="my-4">
            <h3 className='lead fs-md fw-bold mt-6'>
                What Is the Codevixens Academy?
            </h3>

            <p className='mb-1'>
                The Codevixens Academy is a 7-week intensive program that provides specialized training in high-demand tech fields.
                Designed for beginners and intermediate learners, the Academy focuses on hands-on learning and skill-building to prepare you for real-world challenges. Whether you’re just starting your tech
                journey or looking to advance your skills, Our Academy
                Program offer a structured, supportive environment to help you
                achieve your career goals.
            </p>

            <h3 className="lead fs-md fw-bold mt-6">Who Should Enroll?</h3>
            <p className="mb-2">Codevixens Schools are designed for:</p>
            <ul className="icon-list bullet-bg bullet-soft-navy"><li><span><i className="uil uil-check"></i></span>Aspiring tech professionals looking to start their careers.</li><li><span><i className="uil uil-check"></i></span>Current tech professionals seeking to advance their skills and knowledge.</li><li><span><i className="uil uil-check"></i></span>Individuals looking to make a career switch into the tech industry.</li><li><span><i className="uil uil-check"></i></span>Anyone passionate about technology and eager to learn.</li></ul>

            <h3 className="lead fs-md fw-bold mt-7 mb-1">How Our Academy is Structured</h3>
            <p>Our academy is designed for clarity and focus, ensuring you gain the skills you need in just 7 weeks. Here’s how it works:</p>
            <div className='row gx-lg-8 gx-xl-12 gy-6 px-xl-5'>
                <div className='col-lg-4 ps-2'>
                    <div className="d-flex flex-row mb-3">
                        <div>
                            <span className="icon btn btn-block btn-navy pe-none me-5"><span className="number fs-18">1</span></span>
                        </div>
                        <div>
                            <h4 className="mb-1 fs-17 fw-bolder">Schools</h4>
                            <p className="mb-0">Broad categories of learning, such as the CloudDevOps School or the Data Engineering School, catering to different tech disciplines.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 ps-2'>
                    <div className="d-flex flex-row mb-3">
                        <div>
                            <span className="icon btn btn-block btn-navy pe-none me-5"><span className="number fs-18">2</span></span>
                        </div>
                        <div>
                            <h4 className="mb-1 fs-17 fw-bolder">Tracks</h4>
                            <p className="mb-0">Within each school, we have specialized focus areas, such as Cloud Engineering or Database Administration.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 ps-2'>
                    <div className="d-flex flex-row mb-3">
                        <div>
                            <span className="icon btn btn-block btn-navy pe-none me-5"><span className="number fs-18">3</span></span>
                        </div>
                        <div>
                            <h4 className="mb-1 fs-17 fw-bolder">Levels</h4>
                            <p className="mb-0">Tracks are divided into beginner and intermediate levels, ensuring that learners of all backgrounds can find the right starting point.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 ps-2 mt-3'>
                    <div className="d-flex flex-row mb-6">
                        <div>
                            <span className="icon btn btn-block btn-navy pe-none me-5"><span className="number fs-18">4</span></span>
                        </div>
                        <div>
                            <h4 className="mb-1 fs-17 fw-bolder">Courses</h4>
                            <p className="mb-0">Each level includes detailed courses that cover specific skills, tools, and projects needed to master your track.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Introduction;
