import React from 'react';
import {Link} from 'react-router-dom'
import Hero from './includes/Hero';
import MentorsTestimonials from './includes/MentorsTestimonials';

const WorkingAtCodeVixens = () => {
	return (
		<div id="working-at-codevixens">
			
			<section className="wrapper bg-light pt-7">
				<div className="container pb-14 pb-md-10">
					
						<Hero/>
						<section className="pt-4">
							<div className="col-lg-12">
							<h2 className="h1 mb-3">Our Cultures & Values</h2>
								<ul className='icon-list bullet-bg bullet-soft-navy'>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Collaboration: </strong>Focus on teamwork, cross-functional collaboration, and open communication.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Inclusivity: </strong>Codevixens is dedicated to diversity and creating an environment where everyone feels welcome and valued.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Empowerment & Learning: </strong> Emphasize how continuous learning and personal growth are central to our culture (e.g., mentorship opportunities, practical experience etc).
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Flexilbility: </strong>Focus on remote work and, flexible hours to encourage work-life balance.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Innovation: </strong>Stress a culture of creativity and the use of modern tools & technologies.
									</li>
								</ul>
								
							</div>
						</section>
						
						<section className="pt-4">
							<div className="col-lg-12">
							<h2 className="h1 mb-3">Why Work at Codevixens?</h2>
								<ul className='icon-list bullet-bg bullet-soft-navy'>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Growth Opportunites: </strong>Access to internal and external professional development, including mentorship and learning programs.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Remote-Friendly: </strong> Fully remote team with flexible hours.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Supportive Environment: </strong> Work in a positive, inclusive space with empathetic leadership and collaboration.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Impactful Work: </strong>Highlight the rewarding nature of being part of a mission-driven company that’s making a difference.
									</li>
								</ul>
								
							</div>
						</section>
						
						<MentorsTestimonials/>

						<section className="pt-3">
							<div className="col-lg-12">
							<h2 className="h1 mb-3">How We Hire?</h2>
								<ul className='icon-list bullet-bg bullet-soft-navy'>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Application: </strong> A call for application is sent out with directives on how to apply.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Initial Interview: </strong> Selected candidates get interviewed by the Programs Director.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Skills Assessment: </strong> For technical roles especially, a skills assessment test is being done to ensure the candidate fits the profile.
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Final Interview: </strong>Candidates undergo a final interview with one of the program managers assessing their work ethics and culture. 
									</li>
									<li><span><i className="uil uil-check"></i></span>
										<strong>Offer and Onboarding: </strong>Successful candidates who pass the above steps are then offered the role and onboarded to the team.
									</li>
								</ul>
								
							</div>
						</section>
					</div>
			</section>
		</div>
	);
};
export default WorkingAtCodeVixens;
