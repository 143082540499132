import React from 'react';
import { Link } from 'react-router-dom';
const registrationUrl = process.env.REACT_APP_BAND_4_REGISTRATION_URL;
import calendar from '../../../../assets/img/icons/solid/calendar.svg';

const Hero = () => {
    const heroImg = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1734390725/website/8069376_vahe46.jpg';
    // const heroImg = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1734391008/website/3183144-2_rhwbmg.jpg';
    // const heroImg = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1717383182/website/pexels-joshua-mcknight-442355-1139319_p994jx.jpg';
    // const heroImg = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1718928756/website/pexels-yankrukov-8199596_w6pqjp.jpg';

    return (
        <>
            <section className='wrapper bg-light position-relative d-lg-flex align-items-center container'>
                <div className='col-lg-6 py-10'>
                    <div
                        className='mt-lg-10 mt-5 pt-lg-11 mt-md-11 mt-lg-n10 px-md-10 ps-lg-0 pe-lg-11 text-center text-lg-start me-lg-14'
                        data-cues='slideInDown'
                        data-group='page-title'
                        data-delay='600'
                        data-disabled='true'>
                        <h2 className="fs-16 text-uppercase text-muted mb-1 animation-default animation-delay-700"
                            data-cue='slideInDown'
                            data-group='page-title'
                            data-delay='600'
                            data-show='true'
                        >Invest in your tech career</h2>
                        <h1
                            className='display-1 mb-3 animation-default animation-delay-700 fw-light'
                            data-cue='slideInDown'
                            data-group='page-title'
                            data-delay='100'
                            data-show='true'
                            data-words=""
                        >
                            Transforming Passion into Tech Careers –
                            <span>Affordable, Hands-On Learning</span>.
                        </h1>
                        <p
                            className='lead fs-16 mb-4 animation-default animation-delay-900 pe-7'
                            data-cue='slideInDown'
                            data-group='page-title'
                            data-delay='600'
                            data-show='true'>
                            Join thousands of learners building real-world skills in DevOps, Cloud, and Data.
                        </p>
                        <div
                            className='d-flex justify-content-center justify-content-lg-start flex-xl-row flex-column-reverse animation-default animation-delay-900'
                            data-cues='slideInDown'
                            data-group='page-title-buttons'
                            data-delay='900'
                            data-cue='slideInDown'
                            data-disabled='true'
                            data-show='true'>
                            <span
                                className='animation-default animation-delay-1200'
                                data-cue='slideInDown'
                                data-group='page-title-buttons'
                                data-delay='900'
                                data-show='true'>
                                <Link className='btn btn-sm btn-dark me-sm-2 fs-15' to={'/programs/work-ready-program'}>Join Our Work-Ready Program - Cohort 1</Link>
                            </span>
                            <span
                                className='m-xl-0 mb-3 animation-default animation-delay-1500'
                                data-cue='slideInDown'
                                data-group='page-title-buttons'
                                data-delay='900'
                                data-show='true'>
                                <a
                                    href='#our-educational-programs'
                                    className='btn btn-sm btn-outline-dark fs-15'>
                                    Explore Our Programs
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <figure className="position-relative py-5" data-cues='slideInDown'
                        data-group='page-title'
                        data-delay='600'
                        data-disabled='true'>
                        <img className='rounded animation-default animation-delay-700' src={heroImg} srcSet={`${heroImg} 2x`} alt="" />
                    </figure>
                </div>
            </section>

            <section className="wrapper">
                <div className="container pb-4 pb-md-4">
                    <div className="row gx-md-5 gy-5 text-center">
                        <div className="col-md-6 col-xl-3">
                            <div className="card card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h6>Affordable Tech Education ✅</h6>
                                </div>
                                {/* <!--/.card-body --> */}
                            </div>
                            {/* <!--/.card --> */}
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h6>Certificate of Completion ✅</h6>
                                </div>
                                {/* <!--/.card-body --> */}
                            </div>
                            {/* <!--/.card --> */}
                        </div>
                        {/* <!--/column --> */}
                        <div className="col-md-6 col-xl-3">
                            <div className="card card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h6>Quality Training ✅</h6>
                                </div>
                                {/* <!--/.card-body --> */}
                            </div>
                            {/* <!--/.card --> */}
                        </div>
                        {/* <!--/column --> */}

                        {/* <!--/column --> */}
                        <div className="col-md-6 col-xl-3">
                            <div className="card card-border-end border-soft-dark">
                                <div className="card-body text-start px-6 py-4">
                                    <h6>Experienced Instructors ✅</h6>
                                </div>
                                {/* <!--/.card-body --> */}
                            </div>
                            {/* <!--/.card --> */}
                        </div>
                        {/* <!--/column --> */}
                    </div>
                    <figure className='pt-5'>
                        <blockquote className="fs-md">
                            <p>
                                Learning at Codevixens was a game-changer for my career! I gained a solid
                                understanding of fundamental software engineering concepts, which
                                have made me a smarter engineer. I now collaborate more effectively
                                with technical teams, which has greatly enhanced
                                my professional opportunities.
                            </p>
                        </blockquote>
                        <figcaption>
                            <span className="blockquote-footer text-primary fs-md">Adeyinka Oresanya,</span>{" "}
                            <span className='text-grey fs-12 p-0 m-0'>Open source Maintainer and Advocate @ CHAOSS</span>
                        </figcaption>
                    </figure>
                </div>
                {/* <!-- /.container --> */}
            </section>

            <section className="wrapper pt-5 mt-6">
                <div className="container py-4 py-md-4">
                    <div className='col-lg-10 col-xl-11 col-xxl-10 mx-auto'>
                        <div className="row text-center">
                            <div className="col-lg-12">
                                <div className="mb-6">
                                    <span className="mb-25">Enrol With Us</span>
                                    <h2 className="display-5">Which Of Our Programs is Suitable For You?</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card card-body">
                                    <div className="d-flex row">
                                        <div className="col">
                                            <h4 className="">Are you looking to <b>Learn</b>?</h4>
                                            <p>Check out our Academy courses in: <b>Database Administration</b>, <b>Cloud</b> and <b>DevOps Engineering</b>.</p>
                                            <div className="pt-0">
                                                <Link className="btn btn-dark btn-sm" to={'/academy-program'}>Check Out Our Courses</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card card-body">
                                    <div className="d-flex row" d-flex="">
                                        <div className="col">
                                            <h4 className="">Are you looking to <b>build & Grow</b>?</h4>
                                            <p>Check out our upcoming 20-Days Portfolio Challenges.</p>
                                            <div className="pt-0">
                                                <Link className="btn btn-dark btn-sm" to={'/academic-calendar'}>Check Out Our Calendar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="card card-body">
                                    <div className="d-flex row" d-flex="">
                                        <div className="col">
                                            <h4 className="">Do you want to be <b>Work-Ready</b>?</h4>
                                            <p>Become career ready with our <b>12 week</b> work-ready program.</p>
                                            <div className="pt-0">
                                                <Link className="btn btn-dark btn-sm" to={'/programs/work-ready-program'}>Enrol In Our Upcoming Program</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper mt-8">
                <div className="container py-4 py-md-4">
                    <div className='text-center'>
                        <h2 className="fs-15 text-uppercase text-muted mb-0">Start Learning</h2>
                        <h3 className="display-6 mb-2 px-lg-19 px-xl-18">Choose from our popular courses</h3>
                    </div>
                    <div className="pt-5 row gx-5">
                        <div className="col-md-6 mb-5">
                            <div className="rounded bg-light">
                                <div className='card'>
                                    <div className='row'>
                                        <div className="col-5">
                                            <span className="avatar bg-white text-dark position-absolute text-uppercase fs-13 rounded w-10" style={{ top: '0.5rem', left: '0.6rem' }}><span>Open</span></span>
                                            <img className='w-100 h-100 object-fit-cover' src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734376908/website/5685973_ne5ya7.jpg" alt="Work-Ready Program - Cohort 1" />
                                        </div>
                                        <div className="col-7 py-4">
                                            <div className='pb-2'>
                                                <Link className="badge rounded-pill bg-dark" to={'/programs/work-ready-program'}>7 weeks</Link>
                                            </div>
                                            <h4 className='mb-1'>
                                                <Link className="link-body" to={'/programs/work-ready-program'}>Work-Ready Program - Cohort 1</Link>
                                            </h4>
                                            <p className="fs-15 mb-1"><b>Career Paths:</b> Database Administration, DevOps Engineering, Cloud Engineering &amp; Software Engineering</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="rounded bg-light">
                                <div className='card'>
                                    <div className='row'>
                                        <div className="col-5">
                                            <span className="avatar bg-soft-navy text-navy position-absolute text-uppercase fs-13 rounded px-4" style={{ top: '0.5rem', left: '0.6rem' }}><span>Coming Soon</span></span>
                                            <img className='w-100 h-100 object-fit-cover' src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734390179/website/8553950_s9alhx.jpg" alt="" />
                                        </div>
                                        <div className="col-7 py-4 ">
                                            <div className='pb-2'>
                                                <a href="#" onClick={(e) => e.preventDefault()} className="badge rounded-pill bg-soft-navy text-navy" rel="category">10 Days</a>
                                            </div>
                                            <h4 className='link-body'>
                                                2025 Portfolio Building Challenge - Database Administration
                                            </h4>
                                            <p className="fs-15 mb-4"><b>Technical Focus:</b> Database Administration</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-5">
                        <div className="col-md-6 mb-5">
                            <div className="rounded bg-light">
                                <div className='card'>
                                    <div className='row'>
                                        <div className="col-5">
                                            <span className="avatar bg-white text-dark position-absolute text-uppercase fs-13 rounded px-4" style={{ top: '0.5rem', left: '0.6rem' }}><span>Coming Soon</span></span>
                                            <img className='w-100 h-100 object-fit-cover' src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734391077/website/6150527-2_bxytyi.jpg" alt="Tech Launchpad Workshop Series - Advanced Docker Workshop" />
                                        </div>
                                        <div className="col-7 py-4">
                                            <div className='pb-2'>
                                                <a href="#" onClick={(e) => e.preventDefault()} className="badge rounded-pill bg-dark" rel="category">1 Week</a>
                                            </div>
                                            <h4 className='link-body'>Tech Launchpad Workshop Series - Advanced Docker Workshop</h4>
                                            <p className="fs-15 mb-4"><b>Technical Focus:</b> Containerization with Docker</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-5">
                            <div className="rounded bg-light">
                                <div className='card'>
                                    <div className='row'>
                                        <div className="col-5">
                                            <span className="avatar bg-white text-dark position-absolute text-uppercase fs-13 rounded px-4" style={{ top: '0.5rem', left: '0.6rem' }}><span>Coming Soon</span></span>
                                            <img className='w-100 h-100 object-fit-cover' src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734391008/website/3183144-2_rhwbmg.jpg" alt="" />
                                        </div>
                                        <div className="col-7 py-4">
                                            <div className='pb-2'>
                                                <a href="#" onClick={(e) => e.preventDefault()} className="badge rounded-pill bg-dark" rel="category">1 Week</a>
                                            </div>
                                            <h4 className='link-body'>Tech Launchpad Workshop Series - Programming with Dart Workshop</h4>
                                            <p className="fs-15 mb-4"><b>Technical Focus:</b> Software Engineering using Dart</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='our-educational-programs' className="wrapper mt-9 pt-4">
                <div className="container py-4 py-md-4">
                    <div className="row">
                        <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto text-center">
                            <h2 className="text-uppercase text-muted mb-1 display-6">Our Educational Programs</h2>
                            <h3 className="mb-6 mb-md-6 px-lg-10 lead fs-md fw-bold">Browse Through Our Programs and Find the Perfect Fit for Your Career Path</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center bd-highlight mb-3 col-md-12 offset-md-1 col-lg-12 offset-lg-2 mx-auto">
                        <div className="card shadow-none col-md-3 px-lg-0">
                            <div className='card-body text-start shadow lift'>
                                <div className="post-header">
                                    <div className="post-category text-line text-navy">
                                        <a href="#" onClick={(e) => e.preventDefault()} className="hover link-body" rel="category">Duration: 1 Week</a>
                                    </div>
                                    <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="#" onClick={(e) => e.preventDefault()}>Technical Workshops</a></h2>
                                </div>
                                <div className="post-content">
                                    <p>An online 1-week technical workshop series on core in-demand concepts in Data Engineering, Cloud and DevOps Engineering.</p>
                                    {/* <a href="#" className="more hover link-navy" onClick={(e) => e.preventDefault()}>Learn More</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none col-md-3 px-lg-0">
                            <div className='card-body text-start shadow lift'>
                                <div className="post-header">
                                    <div className="post-category text-line text-navy">
                                        <Link to={'/programs/work-ready-program'} className="hover link-body">Duration: 12 weeks</Link>
                                    </div>
                                    <h2 className="post-title h3 mt-1 mb-3">
                                        <Link className="link-dark" to="/programs/work-ready-program">Work-Ready Program</Link>
                                    </h2>
                                </div>
                                <div className="post-content">
                                    <p>You're done learning, take your technical skills to the next level and become career ready with our 12-week work ready program</p>
                                    <Link className="more hover link-navy" to="/programs/work-ready-program">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none col-md-3 px-lg-0">
                            <div className='card-body text-start shadow lift'>
                                <div className="post-header">
                                    <div className="post-category text-line text-navy">
                                        <a href="/academy-program" className="hover link-body" rel="category">Duration: 7 Weeks</a>
                                    </div>
                                    <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="/academy-program">Academy Program</a></h2>
                                </div>
                                <div className="post-content">
                                    <p>Get specialized training in high-demand tech fields such as: Cloud Engineering, DevOps, and Database Administration.</p>
                                    <a href="/academy-program" className="more hover link-navy">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none col-md-3 px-lg-0">
                            <div className='card-body text-start shadow lift'>
                                <div className="post-header">
                                    <div className="post-category text-line text-navy">
                                        <a href="#" className="hover link-body" rel="category" onClick={(e) => e.preventDefault()}>Duration: 20 Days (Max)</a>
                                    </div>
                                    <h2 className="post-title h3 mt-1 mb-3"><a className="link-dark" href="#" onClick={(e) => e.preventDefault()}>Portfolio Building Challenges</a></h2>
                                </div>
                                <div className="post-content">
                                    <p>Work on real-world projects, receive personalized feedback, and gain valuable insights from experts in 20 days.</p>
                                    {/* <a href="#" className="more hover link-navy" onClick={(e) => e.preventDefault()}>Learn More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
