import React from 'react';

// Privacy Policy Information in Details//
const PrivacyPolicy = () => {
	return (
		<div className=" privacy-policy container my-8 ">
			<div className=" card  d-flex justify-content-center p-8">
				<h2
					className="policy-card-title text-center mb-8 mt-2"
					style={{ fontSize: '2.5rem' }}
				>
					Privacy Policy
				</h2>

				<p className="card-text">
					{/* Introduction */}
					This Privacy Policy describes how{' '}
					<b>Codevixens Limited ("we", "us", or "our") </b>collects, uses, and
					discloses personal information when you visit our website and engage
					with our services. When you access our website or engage with our
					services, we may collect certain personal information, including but
					not limited to:
					<ul>
						<li>Name </li>
						<li>Email address</li>
						<li>Contact details</li>
					</ul>
				</p>

				{/* The kind of Data Collection */}
				<h3>Information We Collect:</h3>
				<ul>
					<li>
						<h4>Personal Information:</h4>
						<p>
							When you register our educational programs or events, we may
							collect your name, email address, phone number, and other contact
							details. <br /> We collect this data from registrations forms, our
							classroom portal, and our newsletters.
							<ul>
								<li>
									<h5>Classroom Portal:</h5>
									<p>
										This portal is used for all our academy classes. The data we
										collect includes:
										<ul>
											<li>Full name</li>
											<li>Email address</li>
											<li>
												Other information (picked from the registration form)
											</li>
										</ul>
									</p>
								</li>
								<li>
									<p>
										<h5>Registration Forms:</h5>
										This is for all application forms used for any of our
										programs. The data we collect includes:
										<ul>
											<li>Full name</li>
											<li>Email address</li>
											<li>Phone number</li>
											<li>Country of residence</li>
											<li>Educational background information</li>
											<li>Other information</li>
										</ul>
									</p>
								</li>
								<li>
									<p>
										<h5>Newsletters: </h5>
										We also collect information when you sign up for our
										newsletters.
									</p>
								</li>
							</ul>
						</p>
					</li>
					<li>
						<h4>Payment Information:</h4>
						<p>
							If you make payments for our services, we may collect payment
							details such as credit card information. <br />
							<b>
								{' '}
								Disclaimer: We do not save your credit card information, we
								simply collect it once to use in processing your payment of our
								services.
							</b>
						</p>
					</li>
					<li>
						<h4>Usage Data:</h4>
						<p>
							We may collect information on how you interact with our website
							and services, including IP addresses, browser information, and
							cookies.
						</p>
					</li>
				</ul>

				{/* Data Usage */}
				<h3>How We Use Your Information:</h3>
				<ul>
					<li>
						We use the information collected to provide and improve our services
						to you.
					</li>
					<li>
						Your personal information may be used to communicate with you about
						upcoming courses, events, or updates related to{' '}
						<b>Codevixens Limited.</b>
					</li>
					<li>
						We may use your data for internal analysis and to enhance the user
						experience on our website.
					</li>
				</ul>

				<h3>Data Sharing and Disclosure:</h3>
				<ul>
					<li>
						We do not sell, trade, or rent your personal information to third
						parties.{' '}
					</li>
					<li>
						We may share your information with service providers who assist us
						in operating our website or conducting our business, to comply with
						legal obligations, to protect our rights and interests, with your
						consent.
					</li>
				</ul>

				<h3>Cookies:</h3>
				<p>
					We use cookies and similar tracking technologies to track activity on
					our website and store certain information. You can instruct your
					browser to refuse all cookies or to indicate when a cookie is being
					sent.
				</p>

				<h3>Data Security:</h3>
				<p>
					We prioritize the security of your personal information but remember
					that no method of transmission over the internet or method of
					electronic storage is 100% secure. That is why we implement
					appropriate security measures to protect your personal information
					against unauthorized access or disclosure.
				</p>
				<h3>Your Choices:</h3>
				<p>
					<ul>
						<li>
							You may choose to opt-out of receiving promotional communications
							from us by contacting us directly.{' '}
						</li>
						<li>
							You can request access to, correction, or deletion of your
							personal information by contacting us.
						</li>
					</ul>
				</p>
				<h3>Changes to This Policy:</h3>
				<p>
					We reserve the right to update this Privacy Policy periodically. Any
					changes will be communicated on our website.
				</p>
				<h3>Contact Us:</h3>
				<p>
					If you have any questions about this Privacy Policy, please contact us
					at{' '}
					<a href="mailto:programs@codevixens.org">programs@codevixens.org</a>.
					<br />
					<p>
						This Privacy Policy is effective as of <b>17/10/2024</b>
					</p>
				</p>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
