import { Link } from 'react-router-dom';
import error from '../assets/img/illustrations/404.png';

const ErrorPage = () => {
    return (
        <div>
            <section className='wrapper bg-light'>
                <div className='container pt-12 pt-md-14 pb-14 pb-md-16'>
                    <div className='row'>
                        <div className='col-lg-9 col-xl-8 mx-auto'>
                            <figure className='mb-10'>
                                <img className='img-fluid' src={error} alt='' />
                            </figure>
                        </div>

                        <div className='col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center'>
                            <h1 className='mb-3'>Oops! Page Not Found.</h1>
                            <p className='lead mb-7 px-md-12 px-lg-5 px-xl-7'>
                                The page you are looking for is not available or
                                has been moved. Try a different page or go to
                                homepage with the button below.
                            </p>
                            <Link
                                to='/'
                                className='btn btn-primary rounded-pill'>
                                Go to Homepage
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ErrorPage;
