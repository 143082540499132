import React from 'react';

const EventCategories = () => {
    return (
        <div
            className='container'
            data-aos='slideInDown'
            data-aos-interval='-200'
            data-aos-delay='900'>
            <div
                id='details'
                className='input-group mb-3 w-75 mx-auto d-md-flex d-none'>
                <span
                    className='h-11 input-group-text bg-soft-ash d-flex justify-content-center align-items-center'
                    style={{
                        borderBottomLeftRadius: '50rem',
                        borderTopLeftRadius: '50rem',
                    }}
                    id='basic-addon1'>
                    <i className='uil uil-search fs-26 text-dark'></i>
                </span>
                <input
                    type='search'
                    className='form-control h-11'
                    style={{
                        borderBottomRightRadius: '50rem',
                        borderTopRightRadius: '50rem',
                    }}
                    placeholder='Search events'
                    aria-label='Search events'
                />
            </div>
            <div
                id='details'
                className='input-group mb-3 mx-auto d-md-none d-flex'>
                <span
                    className='h-11 input-group-text bg-soft-ash d-flex justify-content-center align-items-center'
                    style={{
                        borderBottomLeftRadius: '50rem',
                        borderTopLeftRadius: '50rem',
                    }}
                    id='basic-addon1'>
                    <i className='uil uil-search fs-26 text-dark'></i>
                </span>
                <input
                    type='search'
                    className='form-control h-11'
                    style={{
                        borderBottomRightRadius: '50rem',
                        borderTopRightRadius: '50rem',
                    }}
                    placeholder='Search events'
                    aria-label='Search events'
                />
            </div>
        </div>
    );
};

export default EventCategories;
