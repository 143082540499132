import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
    return (
        <div className="row my-7">
            <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                <span className="badge bg-pale-violet text-violet rounded-pill mb-1">
                    Codevixens Academy
                </span>

                <h3 className="display-4 mb-3">
                    7 Weeks to Boost Your Tech Career with Our Academy Program
                </h3>
                <p>Join our focused, hands-on training program designed to equip you with job-ready skills in Cloud Engineering, DevOps, and Database Administration.</p>
            </div>

            <div className="col-xl-6 d-flex justify-content-center align-items-center">
                <div className="col-xl-6 d-flex justify-content-center align-items-center">
                    <div>
                        <h3 className="h4 mb-0 mb-lg-0">
                            Academic Resources
                        </h3>

                        <div className="row gx-3 pt-2">
                            <div className="col-md-6 pb-md-0 pb-2">
                                <div className="mb-2">
                                    <Link className="btn btn-outline-navy btn-sm w-100" to={'/admissions-process'}>Admission Process</Link>
                                </div>

                                <div>
                                    <Link className="btn btn-outline-navy btn-sm w-100" to={'/admissions-policy'}>
                                        Admission Policy
                                    </Link>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-2">
                                    <Link className="btn btn-outline-navy btn-sm w-100" to={'/financial-assistance'}>
                                        Financial Assistance
                                    </Link>
                                </div>

                                <div>
                                    <Link className="btn btn-outline-navy btn-sm w-100" to={'/academic-calendar'}>
                                        Academic Calendar
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;