import React from 'react';
import EventsHero from './includes/EventsHero';
import EventCategories from './includes/EventCategories';
import { EventCards } from './includes/EventCards';

const Events = () => {
    return (
        <>
            <EventsHero />
            <EventCategories />
            <EventCards />
        </>
    );
};

export default Events;

