import React from 'react';

function ContactUsForm() {
	return (
		<form className="contact-us-form pt-3">
			{/* Full name entry  */}
			<div className="row">
				<div className="col-md-6 col-12">
					<div className="mb-4">
						<label className='fs-15 fw-bold' htmlFor="form_name">Full Name *</label>
						<div className="invalid-feedback">Please enter your full name.</div>
						<input
							id="form_name"
							type="text"
							name=" full-name"
							className="form-control"
							placeholder="Jane Doe"
							required
						/>
					</div>
				</div>

				{/* Enter your email */}
				<div className="col-md-6 col-12">
					<div className="mb-4">
						<label className='fs-15 fw-bold' htmlFor="form_email">Email *</label>
						<input
							id="form_email"
							type="email"
							name="email"
							className="form-control"
							placeholder="jane.doe@example.com"
							required
						/>
						<span className="error" aria-live="polite"></span>
						<div className="invalid-feedback">
							Please provide a valid email address.
						</div>
					</div>
				</div>
			</div>
			{/*Selection of reason*/}
			<div className="col-md-12">
				<div className="form-select-wrapper mb-4">
					<label className='fs-15 fw-bold' htmlFor="form_reason">Reason for Reaching Out *</label>
					<select className="form-select" id="reason" name="reason" required>
						<option value="Inquiry">Inquiry</option>
						<option value="Classroom Issues">Classroom Issues</option>
					</select>
					<div className="invalid-feedback"> Please select a reason. </div>
				</div>
			</div>

			{/* Message Entry */}
			<div className="col-12">
				<div className="mb-4">
					<label className='fs-15 fw-bold' htmlFor="form_message">Message *</label>
					<textarea
						id="form_message"
						name="message"
						className="form-control"
						placeholder="Your message"
						rows="3"
						required
					></textarea>
					<div className="invalid-feedback"> Please enter your messsage. </div>
				</div>
			</div>

			{/* Submit Button */}
			<div className="col-12 text-center">
				<button className="btn btn-dark btn-sm" type="submit">
					Send us a message
				</button>
			</div>
		</form>
	);
}

export default ContactUsForm;
