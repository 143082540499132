import Hero from './includes/Hero.jsx';
import CareerOutlook from './includes/CareerOutlook.jsx';

const DatabaseAdministration = () => {
    return (
        <section className="container wrapper">
            <Hero />
            <CareerOutlook />
        </section>
    );
}

export default DatabaseAdministration;