import React from 'react';

const FinancialAssistance = () => {
    return (
        <div id='financial-assistance'>
            <section className="wrapper bg-light pt-14">
                <div className="container pb-14 pb-md-16">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="h1 mb-3">Financial Assistance Guidelines for Codevixens Paid Programs</h2>
                            <p>
                                At Codevixens, we are committed to supporting our students in achieving their educational goals. 
                                We understand that financial constraints may sometimes prevent students from meeting tuition deadlines. 
                                To assist those who may need extra support, we offer a financial assistance program. 
                                This document outlines the steps to request financial assistance if you are unable to make your tuition payment within the stipulated period after receiving your conditional acceptance.
                            </p>
                            <h3 className="fs-20">Types of Financial Assistance</h3>
                            <p>We offer two types of financial assistance:</p>
                            <ol className="bullet-soft-navy">
                                <li><strong>Split Payment Option</strong></li>
                                <ul className="bullet-soft-navy my-2">
                                    <li className='pb-2'>
                                        <strong>How It Works:</strong> 
                                        Instead of paying the full tuition fee upfront, you may opt to pay 50% of the tuition fee before the original 5-day deadline elapses. 
                                        You will then have an additional 10 days to complete the remaining 50%.
                                    </li>
                                    <li>
                                        <strong>Conditions:</strong> 
                                        You must adhere to the agreed-upon payment schedule. 
                                        Failure to complete the full payment within the 10-day extension may result in the cancellation of your enrollment.
                                    </li>
                                </ul>
                                <li><strong>Payment Window Extension</strong></li>
                                <ul className="bullet-soft-navy my-2">
                                    <li className='pb-2'>
                                        <strong>How It Works:</strong> 
                                        If the 5-day payment deadline is not feasible for you, you may request an extension of the payment window. 
                                        This extension provides additional time beyond the original deadline but requires full payment when the new deadline is reached. 
                                        Please note that the maximum extension period you can request is 7 additional days.
                                    </li>
                                    <li>
                                        <strong>Conditions:</strong> 
                                        The length of the extension will be determined based on your request and circumstances. 
                                        No further extensions will be granted, so it is important that you make the full payment by the revised deadline.
                                    </li>
                                </ul>
                                <p className='alert alert-warning mt-2'>
                                    <strong>Important:</strong> All payments must be made before the start of classes, regardless of any extension. Please keep this in mind when selecting an extension period to ensure that you meet this requirement.
                                </p>
                            </ol>
                            <h3 className="fs-20 mt-5 pt-3">Eligibility Criteria</h3>
                            <p>To be considered for financial assistance, you must meet the following criteria:</p>
                            <ol className="bullet-soft-navy">
                                <li className='pb-2'>You have received a conditional acceptance to one of our programs.</li>
                                <li className='pb-2'>You are unable to make the full tuition payment by the deadline due to financial constraints.</li>
                                <li>You are committed to completing the program if financial assistance is granted.</li>
                            </ol>
                            <h3 className="fs-20 mt-5 pt-3">How to Request Financial Assistance</h3>
                            <p className='mb-2'><strong>Step 1: Submit a Financial Assistance Request</strong></p>
                            <p>Reply directly to the conditional acceptance email that was sent to you. In your reply, include the following information:</p>
                            <ol className="bullet-soft-navy">
                                <li className='pb-2'>A brief explanation of your current financial situation and why you are requesting assistance</li>
                                <li>The type of financial assistance you are requesting (<i>Split Payment</i> or <i>Payment Window Extension</i>)</li>
                            </ol>
                            <p className='mb-2'><strong>Step 2: Await Review</strong></p>
                            <p>
                                Your assigned Programs Manager at Codevixens will present your request to our team. 
                                We will review it and may reach out to you for further clarification if needed. 
                                Please allow up to 36 hours for us to process your request.
                            </p>
                            <p className='mb-2'><strong>Step 3: Receive a Decision</strong></p>
                            <p>
                            Once your request has been reviewed, we will notify you of our decision via email. 
                            If your request is approved, we will provide you with the next steps for adjusting your payment plan and completing your enrollment.
                            </p>
                            <div className='alert alert-info mt-4'>
                                <p className='mb-2'><strong>Important Information:</strong></p>
                                <ol className="bullet-soft-navy">
                                    <li className='pb-2'>Financial assistance is not guaranteed and will be granted based on the availability of funds and the applicant’s demonstrated need.</li>
                                    <li className='pb-2'>If you are granted financial assistance, you will still be responsible for fulfilling the program’s requirements, including attendance and participation.</li>
                                    <li>Failure to adhere to the program’s terms, even with financial assistance, may result in the revocation of your enrollment.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FinancialAssistance;
