const SchoolFaq = () => {
    return (
        <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
                <div className="row gx-lg-8 gx-xl-12 gy-10">
                    <div className="col-lg-6 mb-0">
                        <h2 className="fs-15 text-uppercase text-primary mb-3">FAQ</h2>
                        <h3 className="display-5 mb-4">If you don't see an answer to your question, you can send us an email from our contact form.</h3>
                        <p className="lead mb-6">Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget urna mollis ornare.</p>
                        <a href="#" className="btn btn-primary rounded-pill">All FAQ</a>
                    </div>
                    <div className="col-lg-6">
                        <div id="accordion-3" className="accordion-wrapper">
                            <div className="card accordion-item">
                                <div className="card-header" id="accordion-heading-3-1">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-3-1" aria-expanded="false" aria-controls="accordion-collapse-3-1">How do I get my subscription receipt?</button>
                                </div>
                                <div id="accordion-collapse-3-1" className="collapse" aria-labelledby="accordion-heading-3-1" data-bs-target="#accordion-3">
                                    <div className="card-body">
                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card accordion-item">
                                <div className="card-header" id="accordion-heading-3-2">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-3-2" aria-expanded="false" aria-controls="accordion-collapse-3-2">Are there any discounts for people in need?</button>
                                </div>
                                <div id="accordion-collapse-3-2" className="collapse" aria-labelledby="accordion-heading-3-2" data-bs-target="#accordion-3">
                                    <div className="card-body">
                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card accordion-item">
                                <div className="card-header" id="accordion-heading-3-3">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-3-3" aria-expanded="false" aria-controls="accordion-collapse-3-3">Do you offer a free trial edit?</button>
                                </div>
                                <div id="accordion-collapse-3-3" className="collapse" aria-labelledby="accordion-heading-3-3" data-bs-target="#accordion-3">
                                    <div className="card-body">
                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card accordion-item">
                                <div className="card-header" id="accordion-heading-3-4">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-3-4" aria-expanded="false" aria-controls="accordion-collapse-3-4">How do I reset my Account password?</button>
                                </div>
                                <div id="accordion-collapse-3-4" className="collapse" aria-labelledby="accordion-heading-3-4" data-bs-target="#accordion-3">
                                    <div className="card-body">
                                        <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Cras justo odio, dapibus ac facilisis.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SchoolFaq;