import React from 'react';
import Header from './includes/Header';
import Positions from './includes/Positions';

const OpenPositions = () => {
    return (
        <div id='open-positions'>
            <Header />
            <Positions />
        </div>
    );
};

export default OpenPositions;
