import React from 'react';

const AdmissionProcess = () => {
    return (
        <div id='admissions-process'>
            <section className="wrapper bg-light pt-14">
                <div className="container pb-14 pb-md-16">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="h1 mb-3">Admission Process</h2>
                            <p>
                                Welcome to Codevixens! 💜 <span className='d-block' style={{paddingBottom: "7px"}}></span>
                                If you are viewing this, chances are you are interested in applying for an educational program with us. 
                                Our admission process seeks out enthusiastic learners who are eager to advance their skills in technology and coding. 
                                <span className='d-block' style={{paddingBottom: "7px"}}></span>
                                Below is an overview of the steps involved in applying to our programs:
                            </p>
                            <h3 className="fs-20">1. Program Selection:</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Review our program offerings to determine which one best aligns with your interests, career goals, and skill level.</li>
                                <li> - Explore the detailed descriptions of each program to understand the curriculum, learning objectives, and prerequisites.</li>
                            </ul>
                            <h3 className="fs-20">2. Online Application:</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Complete our online application form, which collects essential information such as personal details, educational background, and technical experience (if any).</li>
                                <li> - Ensure that all required fields are accurately filled out and any supporting information are uploaded as instructed.</li>
                            </ul>
                            <h3 className="fs-20">3. Application Review:</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Our programs team will carefully review your application to assess your qualifications, motivation, and potential for success in the chosen program.</li>
                                <li> - We consider factors such as academic background, technical experience, personal statement, and any additional materials submitted.</li>
                            </ul>
                            <h3 className="fs-20">4. Interview (if applicable):</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Some of our programs may require an interview as part of the admissions process.</li>
                                <li> - The interview allows us to better understand your goals, experiences, and suitability for the program.</li>
                            </ul>
                            <h3 className="fs-20">5. Admission Decision:</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Upon completion of the application review process, you will be notified of the admission decision.</li>
                                <li> - If accepted, you will receive detailed instructions on how to proceed with enrollment, including writing 
                                    of an entrance evaluation test (if the selected course requires it) and paying the tuition fees.
                                </li>
                            </ul>
                            <h3 className="fs-20">6. Enrollment Confirmation:</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Once you have been accepted into the program and completed the enrollment requirements, you will receive confirmation of your enrollment.</li>
                                <li> - This confirmation will include important information such as program start date, program details, instructor details, getting started resources, and next steps.</li>
                            </ul>
                            <h3 className="fs-20">7. Orientation and Onboarding:</h3>
                            <ul className="icon-list bullet-soft-navy">
                                <li> - Prior to the start of the program, you may be required to attend an orientation session to familiarize yourself with the program structure, resources, and expectations.</li>
                                <li> - Our team will provide support and guidance throughout the onboarding process to ensure a smooth transition into the program.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdmissionProcess;