import React from 'react';
// import vesi from '../img/vesicash.png';
// import ngc from '../img/ngc.png';
// import fa from '../img/fa.png';
// import mal from '../img/mal.png';

function OurPartners() {
    const companies = [
        "https://res.cloudinary.com/dar3e312jder/image/upload/v1697603361/website/partners%20and%20collaborators/Propel_Logo_Black_dlxrsm.png", 
        "https://res.cloudinary.com/dar3e312jder/image/upload/v1697603479/website/partners%20and%20collaborators/Infobip-logo_kc3ram.svg", 
        'https://res.cloudinary.com/dar3e312jder/image/upload/v1694471500/website/partners%20and%20collaborators/at_23.31.18_cadc8v.png', 
        'https://res.cloudinary.com/dar3e312jder/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1694471277/website/partners%20and%20collaborators/dGhEpc2l_400x400_bniprt.jpg', 
        'https://res.cloudinary.com/dar3e312jder/image/upload/t_Test/v1694471293/website/partners%20and%20collaborators/image_aenpa6.png', 
    ];

    return (
        <>
            <section id="snippet-10" className="wrapper bg-light pt-13">
                <div className="container pt-0 pb-0">
                    <div className="text-center">
                        <h2 className="display-6 mb-1">Our Partners & Collaborators</h2>
                        <p className="lead fs-18 mb-7 col-8 mx-auto">Discover our prestigious partners and collaborators who share our vision for high quality, affordable, gbobal tech education.</p>
                    </div>
                    <div className="swiper-container clients mt-5 pt-3" data-margin="30" data-reverse="true" data-dots="false" data-loop="true" data-autoplay="true" data-autoplaytime="1" data-drag="true" data-speed="9000" data-items-xxl="6" data-items-xl="6" data-items-lg="6" data-items-md="4" data-items-xs="2">
                        <div className="swiper">
                            <div className="swiper-wrapper ticker">
                                {
                                    companies.map((img, key) => (
                                        <div className="swiper-slide px-5 h-100px align-items-center d-flex" key={key}>
                                            <img src={img} alt="" />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurPartners;
