import React from 'react';

const MentorsTestimonials = () => {
    return (
        <div id='mentors-testimonials-page' className="pt-4">
                    <div className="row">
                        <div className="col-md-10">
                        <h2 className="h1 mb-3">What our Team says</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid col-md-12 mb-lg-10">
                            <div className="row isotope gy-6">
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">Working at Codevixens has been a great joy. 
                                                    From an impressive team spirit, to kind colleagues and a respectful 
                                                    work culture, everyday is a learning experience. </p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Tiku Gaelle</h5>
                                                        <p className="mb-0 fs-15">Programs Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">

                                                <p className="fs-15 mb-2">Working at Codevixens has been life-changing, offering immense growth opportunities. The collaborative environment and supportive leadership have empowered me.</p>

                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Chinaza Igboanugo</h5>
                                                        <p className="mb-0 fs-15">Programs Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">
                                                Working at Codevixens has been a smooth and exciting journey. I started as a volunteer last October and became a 
                                                full-time digital marketer in February.
                                                </p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Nwankwo Gift Chizaram</h5>
                                                        <p className="mb-0 fs-15">Programs Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    );
};

export default MentorsTestimonials;