import React from 'react';
import { Link } from 'react-router-dom';
import pic1 from '../../../../assets/img/photos/b10.jpg'
import { events_details } from './events';

export const EventCards = () => {
    return (
        <div className='container py-10'>
            <h4 className='my-5 text-navy text-uppercase ls-xl'>Events</h4>
            <div className='row g-6'>
                {events_details.map((dets, id)=>(
                    <Link to='/events/1' className='col-lg-3 col-md-3' key={dets.id}>
                    <div className='event-card' style={{position:'relative'}}>
                    <span className="badge bg-navy text-white w-15 position-absolute text-uppercase fs-12" style={{ top: '0.5rem', right: '1rem', zIndex: 90 }}>
                            <span>{dets.event_timeline}</span>
                        </span>
                        <div className='card border-bottom border-5  border-navy'>
                            <img className='card-img-top' src={pic1} alt='' />
                            <div className='card-body px-4 pt-5 pb-2'>
                                <div className="post-header">
                                    <div className="post-category">
                                        <a href="https://www.youtube.com/watch?v=vRDRB4ocd60&list=PL7smjLhudrwToHxX8zDOE0mzWXMLQ6Ztw&index=5" className="hover link-navy" rel="category">Orientation Session</a>
                                    </div>
                                    <h2 className="post-title h3 mt-1 mb-3 fs-17">
                                        <a className="link-navy" href="https://www.youtube.com/watch?v=vRDRB4ocd60&list=PL7smjLhudrwToHxX8zDOE0mzWXMLQ6Ztw&index=5">
                                            {dets.event_name}
                                        </a>
                                    </h2>
                                </div>
                                <p className='mb-0 d-flex align-items-center'>
                                    <i className='uil uil-calendar-alt text-navy fs-21 me-2'></i>{' '}
                                    <span className='text-navy fs-15'>{dets.event_date}</span>
                                </p>
                                <p className='mb-2 d-flex align-items-center'>
                                    <i className='uil uil-clock text-navy fs-21 me-2'></i>{' '}
                                    <span className='text-navy fs-15'>Time: {dets.event_time}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
                ))} 
            </div>
        </div>
    );
};
