const Calendar = () => {
    return (
        <section className="mt-5 pt-3">
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="bg-soft-primary" colSpan={4}>Work-Ready Program</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-2 fw-bold" scope="col">Cohort</td>
                            <td className="py-2 fw-bold" scope="col">Date</td>
                            <td className="py-2 fw-bold" scope="col">Event Type</td>
                            <td className="py-2 fw-bold" scope="col">Description</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row" rowSpan={3}>Cohort 1</td>
                            <td className="py-2">Tuesday, 17th of December 2024</td>
                            <td className="py-2"><span className="badge bg-leaf rounded-pill">Registration Opens</span></td>
                            <td className="py-2">Cohort 1 registration begins.</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row">Wednesday, 15th of January 2025</td>
                            <td className="py-2"><span className="badge bg-danger rounded-pill">Registration Closes</span> </td>
                            <td className="py-2">Cohort 1 registration closes.</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row">Monday, 20th of January 2025</td>
                            <td className="py-2"><span className="badge bg-primary rounded-pill">Program Commences</span> </td>
                            <td className="py-2">Cohort 1 program commences.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="table-responsive">
                <table className="table table-bordered mt-9">
                    <thead>
                        <tr>
                            <th className="bg-soft-primary" colSpan={4}>Academy Program</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-soft-navy">
                            <td className="py-2 fw-bold" scope="col">Batch</td>
                            <td className="py-2 fw-bold" scope="col">Date</td>
                            <td className="py-2 fw-bold" scope="col">Event Type</td>
                            <td className="py-2 fw-bold" scope="col">Description</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row" rowSpan={4}>CAS 2025 - Batch A</td>
                            <td className="py-2">Monday, 13th of January 2025</td>
                            <td className="py-2"><span className="badge bg-leaf rounded-pill">Registration Opens</span></td>
                            <td className="py-2">CAS 2025 - Batch A registration begins.</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row">Friday, 14th of February 2025</td>
                            <td className="py-2"><span className="badge bg-danger rounded-pill">Registration Closes</span> </td>
                            <td className="py-2">Registration Closes</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row">Monday, 17th of February 2025</td>
                            <td className="py-2"><span className="badge bg-yellow rounded-pill">Welcome Session</span> </td>
                            <td className="py-2">Welcome Session</td>
                        </tr>
                        <tr>
                            <td className="py-2" scope="row">Monday, 17th of February 2025</td>
                            <td className="py-2"><span className="badge bg-primary rounded-pill">Program Commences</span> </td>
                            <td className="py-2">Program Commences</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default Calendar;