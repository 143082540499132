import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Carousel = () => {
    return (
        <>
            <Swiper
                // className='swiper-container'
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={false}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                }}
            >
                <SwiperSlide>
                    <article>
                        {/* overlay overlay-1 hover-scale  */}
                        <figure className="hover-scale rounded mb-3">
                            <a href="#" onClick={(e) => e.preventDefault()}>
                                <img style={{
                                    aspectRatio: '16 / 9',
                                    width: '100%',
                                    objectFit: 'cover'
                                }} src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734700315/website/ibejzk.jpg" alt="" />
                            </a>
                            {/* <figcaption>
                                <h5 className="from-top mb-0">Read More</h5>
                            </figcaption> */}
                        </figure>
                        <div className="post-header">
                            <h2 className="post-title h3 mb-1"><a className="link-navy fs-19" href="#" onClick={(e) => e.preventDefault()}>CloudDevOps School</a></h2>
                        </div>
                        <div className="post-footer">
                            <ul className="post-meta">
                                <li className="post-comments"><a href="#" onClick={(e) => e.preventDefault()}><i className="uil uil-file-alt fs-15"></i>4 Courses</a></li>
                            </ul>
                        </div>
                    </article>
                </SwiperSlide>
                <SwiperSlide>
                    <article>
                        <figure className="hover-scale rounded mb-3">
                            <a href="#" onClick={(e) => e.preventDefault()}>
                                <img style={{
                                    aspectRatio: '16 / 9',
                                    width: '100%',
                                    objectFit: 'cover'
                                }} src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734700592/website/dmlg9j.jpg" alt="" />
                            </a>
                        </figure>
                        <div className="post-header">
                            <h2 className="post-title h3 mb-1"><a className="link-navy fs-19" href="#" onClick={(e) => e.preventDefault()}>Data Engineering School</a></h2>
                        </div>
                        <div className="post-footer">
                            <ul className="post-meta">
                                <li className="post-comments"><a href="#" onClick={(e) => e.preventDefault()}><i className="uil uil-file-alt fs-15"></i>1 Course</a></li>
                            </ul>
                        </div>
                    </article>
                </SwiperSlide>
                <SwiperSlide>
                    <article>
                        <figure className="hover-scale rounded mb-3">
                            <a href="#" onClick={(e) => e.preventDefault()}>
                                <img
                                    src="https://res.cloudinary.com/dar3e312jder/image/upload/v1714098946/website/2151205455_mcvien.jpg"
                                    style={{
                                        aspectRatio: '16 / 9',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }}
                                    alt=""
                                />
                            </a>
                        </figure>
                        <div className="post-header">
                            <h2 className="post-title h3 mb-1"><a className="link-navy fs-19" href="#" onClick={(e) => e.preventDefault()}>School of Sofware Engineering</a></h2>
                        </div>
                        <div className="post-footer">
                            <ul className="post-meta">
                                <li className="post-comments"><a href="#" onClick={(e) => e.preventDefault()}><i className="uil uil-file-alt fs-15"></i>2 Courses</a></li>
                            </ul>
                        </div>
                    </article>
                </SwiperSlide>
            </Swiper>
        </>
    );
}

export default Carousel;