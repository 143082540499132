import WhoWeAre from './includes/WhoWeAre';
import '../../../assets/css/plugins.css'

const AboutUs = () => {
    return (
        <>
            <WhoWeAre />
        </>
    );
}

export default AboutUs;