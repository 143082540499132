import React from 'react';

const AdmissionPolicy = () => {
    return (
        <div id='admissions-policy'>
            <section className="wrapper bg-light">
                <div className="container pb-14 pb-md-16">
                    <div className="row gx-0">
                        <aside className="col-xl-3 sidebar sticky-sidebar mt-md-0 py-14 d-none d-xl-block">
                            <div className="widget">
                                <nav id="sidebar-nav">
                                    <ul className="list-unstyled text-reset">
                                        <li><a className="nav-link scroll" href="#admissions-policy">1. Admissions Policy</a></li>
                                        <li><a className="nav-link scroll" href="#tuition-fee-policies">2. Tuition and Fee Policies</a></li>
                                        {/* <li><a className="nav-link scroll" href="#financial-aid-policy">3. Financial Aid Policy</a></li> */}
                                        <li><a className="nav-link scroll" href="#withdrawal-policy">3. Withdrawal Policy</a></li>
                                        <li><a className="nav-link scroll" href="#grievance-policy">4. Grievance Policy</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </aside>
                        <div className="col-xl-8">
                            <section id="admissions-policy" className="wrapper pt-14">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="h1 mb-3">1. Admissions Policy</h2>
                                        <p>
                                            Welcome to Codevixens! <br/>
                                            Our admissions policy outlines the criteria and procedures for applying to our programs.
                                            We are committed to fostering a diverse and inclusive learning environment where all individuals are given equal opportunity for admission.
                                        </p>
                                        <h3>Application Process:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Program Selection:</span> Applicants are to carefully review our program offerings and select the one that aligns with their interests and career goals.</li>
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Online Application:</span> Prospective students are required to complete our online application form. This form collects essential information such as personal details, educational background, and other information.</li>
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Submission of Materials:</span> Along with the application form, applicants may be required to submit additional materials such as letter of referral, resumes, or portfolios, depending on the program requirements.</li>
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Application Review:</span> Our programs team carefully reviews each application to assess the candidate's qualifications, motivation, and potential for success in the program.</li>
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Interview (if applicable):</span> Some programs may require an interview as part of the admissions process. This interview allows us to better understand the applicant's goals, experiences, and suitability for the program.</li>
                                        </ul>
                                        <h3>Admissions Criteria:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Academic Achievement:</span> While there are no specific GPA requirements for admission, we seek candidates who demonstrate strong academic potential and a genuine passion for learning.</li>
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Personal Statement:</span> Applicants are encouraged to submit a personal statement highlighting their interest in the program, career aspirations, and reasons for choosing Codevixens.</li>
                                            <li><span><i className="uil uil-check"></i></span><span className='fw-bold'>Entrance Examination:</span> All applicants to all our programs with the exception of the <strong className='text-navy'>"20 Days Challenge"</strong> program are required to write an entrance examination. A minimum score of 50% is necessary to successfully pass this examination. This examination is designed to assess applicant's verbal, problem solving skills and lots more.</li>
                                        </ul>
                                        <h3>Selection Criteria:</h3>
                                        <p>Our admissions decisions are based on a holistic review of each applicant's qualifications, including <strong className='text-navy'>academic background</strong>, <strong className='text-navy'>technical experience</strong>, <strong className='text-navy'>personal statement</strong>, <strong className='text-navy'>entrance examination and interview performance (if applicable)</strong>. We seek students who possess the drive to succeed and flourish under the guidance of our dedicated faculty.</p>
                                        
                                        <h3>Non-Discrimination Policy:</h3>
                                        <p>Codevixens does not discriminate on the basis of race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, or age in its admissions process. In our programs, you'll find a welcoming environment that embraces all backgrounds and fosters a sense of belonging. We believe in empowering everyone to succeed, regardless of their differences.</p>

                                        <h3>Contact Us:</h3>
                                        <p>If you have any questions about our admissions policy or the application process, please don't hesitate to contact our programs team at <a className='text-navy fw-bold' href="mailto:programs@codevixens.org">programs@codevixens.org</a></p>
                                    </div>
                                </div>
                            </section>
                            <section id="tuition-fee-policies" className="wrapper pt-14">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="h1 mb-3">2. Tuition and Fee Policies</h2>
                                        <p>
                                        At Codevixens, we offer high-quality educational programs designed to that help individuals to pursue and grow their careers in the vast field of technology. 
                                        <strong className='text-navy'> We believe that a rewarding career in tech shouldn't break the bank</strong>, this is why we provide high-quality coding education at a transparent and accessible cost.
                                        In keeping with our commitment to transparency and making education accessible, we've outlined our tuition and fee policies below.
                                        </p>
                                        <h3>Program Tuition Fees:</h3>
                                        <p>
                                            We offer a range of educational programs tailored to cater to the varied needs and interests of our students.
                                            Our tuition fees vary depending on the program and its duration. Below are the tuition fees for some of our flagship programs:
                                        </p>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Fee</th>
                                                    <th scope="col">Duration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Codevixens Academy</th>
                                                    <td><strong>Up to 86.7% scholarship</strong> <br/> <strike>₦150,000</strike> ❌ ₦30,000 (Max) ✅  <br/>(35USD)</td>
                                                    <td>7 Weeks <br/>[2x Live Class <br/>Every Week]</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Codevixens Fellowship</th>
                                                    <td><strong>80% scholarship</strong> <br/> <strike>₦250,000</strike> ❌ <br />₦50,000 ✅  (45USD)</td>
                                                    <td>10 Weeks</td>
                                                </tr>
                                                {/* <tr>
                                                    <th scope="row">Codevixens Internship</th>
                                                    <td><span className='badge badge-lg bg-primary'>Free</span></td>
                                                    <td>4 Months</td>
                                                </tr> */}
                                                <tr>
                                                    <th scope="row">20 Days Challenge</th>
                                                    <td><span className='badge badge-lg bg-primary'>Free</span></td>
                                                    <td>Min: 5 Days, Max: 20 Days</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p>Please note that tuition fees are subject to change and may vary based on factors such as program enhancements, market conditions, and other considerations.</p>
                                        <h3>Payment Terms:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>At Codevixens, we require full payment of tuition fees upon enrollment. </li>
                                            <li><span><i className="uil uil-check"></i></span>We do offer installment payment plans for some of our programs. Payments can be made securely through our integrated payment platforms, including <strong className='text-navy'>Paystack</strong> and <strong className='text-navy'>Flutterwave</strong>. </li>
                                            <li><span><i className="uil uil-check"></i></span>Upon successful payment, students will receive an invoice confirming their enrollment in the selected program.</li>
                                        </ul>
                                        <h3>Substitution Policy:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>Codevixens does not permit the substitution of enrolled students with other individuals. Enrolled students are expected to participate in the program for which they have registered.</li>
                                        </ul>
                                        <h3>Additional Policies:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span><strong>Late Payment Fee:</strong> A late payment fee may be applied to tuition payments that are not received by the specified deadline.</li>
                                            <li><span><i className="uil uil-check"></i></span><strong>Financial Aid:</strong> Codevixens may offer financial aid or scholarship opportunities to eligible students. 
                                            {/* Please check the "Financial Aid Policy Section" for more information. */}
                                            </li>
                                            <li><span><i className="uil uil-check"></i></span><strong>Taxes:</strong> Tuition fees are exclusive of any applicable taxes, which may be added to the total amount due by our payment service provider.</li>
                                            <li><span><i className="uil uil-check"></i></span><strong>Currency:</strong> All tuition fees are listed in Nigerian Naira (NGN) unless otherwise specified.</li>
                                        </ul>
                                        <h3>Changes to Terms:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>Codevixens reserves the right to update or modify these Tuition and Fee Policies at any time. Any changes will be communicated to enrolled students in a timely manner.</li>
                                        </ul>
                                        <h3>Contact Us:</h3>
                                        <p>For further information or clarification on our tuition and fee policies, please contact our Programs Team. </p>
                                    </div>
                                </div>
                            </section>
                            <section id="withdrawal-policy" className="wrapper pt-14">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="h1 mb-3">4. Withdrawal Policy</h2>
                                        <p>
                                            At Codevixens, we understand that circumstances may arise that necessitate changes to enrollment plans.
                                            This Withdrawal Policy outlines the procedures and guidelines for withdrawing from a program at our educational organisation.
                                        </p>
                                        <h3>1. Withdrawal Process:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>Withdrawal requests must be submitted via mail to our Programs Team.</li>
                                            <li><span><i className="uil uil-check"></i></span>The request should include the student's full name, program name, and reason for withdrawal.</li>
                                        </ul>
                                        <h3>2. Withdrawal Deadlines:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>Withdrawal deadlines vary depending on the program and its duration.</li>
                                            <li><span><i className="uil uil-check"></i></span>Students are encouraged to refer to the program calendar or contact the Programs Team for specific withdrawal deadlines.</li>
                                        </ul>
                                        <h3>3. Refund Eligibility:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>Refund eligibility is determined based on the date of the withdrawal request and the outlined refund policy 👇🏽.</li>
                                            <li><span><i className="uil uil-check"></i></span>Refunds may be subject to a non-refundable administrative fee, which will be deducted from the total refund amount.</li>
                                            <li><span><i className="uil uil-check"></i></span>Refunds are processed within a specified timeframe and are subject to review and approval by Codevixens Programs administration.</li>
                                        </ul>
                                        <h3>4. Refund Policy:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>Refunds are granted according to the following schedule:</li>
                                            <ul className='mt-2'>
                                                <li style={{listStyleType: 'none'}}><span><i className="uil uil-check"></i></span>Withdrawal before the start of the program: 100% refund</li>
                                                <li style={{listStyleType: 'none'}}><span><i className="uil uil-check"></i></span>Withdrawal within the first week of the program: 90% refund</li>
                                                <li style={{listStyleType: 'none'}}><span><i className="uil uil-check"></i></span>No refunds will be issued after the first week of the program.</li>
                                            </ul>
                                        </ul>
                                        <h3>5. Re-Enrollment:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>Unfortunately, we do not allow students who wish to re-enroll in a program after withdrawing.</li>
                                        </ul>
                                        <h3>6. Communication:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>Codevixens will communicate with the student regarding the status of their withdrawal request and any subsequent actions or updates.</li>
                                        </ul>

                                        <h3>7. Contact Us:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>If you have any questions or need further assistance regarding the withdrawal process, please contact the Programs Team at <a className='text-navy fw-bold' href="mailto:programs@codevixens.org">programs@codevixens.org</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <section id="grievance-policy" className="wrapper pt-14">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="h1 mb-3">5. Grievance Policy</h2>
                                        <p>
                                            In the event that a student has a concern or grievance, we have established the following Grievance Policy to ensure that all issues are addressed promptly, fairly, and effectively.
                                        </p>
                                        <h3>1. Scope:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>This Grievance Policy applies to all students enrolled in programs offered by Codevixens.</li>
                                        </ul>
                                        <h3>2. Definition of Grievance:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>A grievance is defined as any concern, complaint, or dispute related to academic or non-academic matters that directly affects a student's experience at Codevixens.</li>
                                        </ul>
                                        <h3>3. Informal Resolution:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>Students are encouraged to first attempt to resolve grievances informally by discussing the issue with the appropriate programs personnel.</li>
                                            <li>If the grievance involves another student, students are encouraged to attempt resolution through direct communication and mediation, if appropriate.</li>
                                        </ul>
                                        <h3>4. Formal Grievance Procedure:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>If the grievance cannot be resolved informally, students may initiate a formal grievance procedure by submitting a written complaint to the Programs Team.</li>
                                            <li><span><i className="uil uil-check"></i></span>The written complaint should include a clear description of the grievance, relevant facts etc.</li>
                                            <li><span><i className="uil uil-check"></i></span>A personnel from the programs office will review the complaint, conduct an investigation if necessary, and work towards a resolution within a reasonable timeframe.</li>
                                        </ul>
                                        <h3>5. Confidentiality:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>All parties involved in the grievance process are expected to maintain confidentiality to the extent possible.</li>
                                            <li><span><i className="uil uil-check"></i></span>Information related to grievances will be shared only with individuals directly involved in the resolution process.</li>
                                        </ul>
                                        <h3>6. No Retaliation:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li><span><i className="uil uil-check"></i></span>Codevixens prohibits retaliation against any individual who files a grievance or participates in the grievance resolution process.</li>
                                            <li><span><i className="uil uil-check"></i></span>Any acts of retaliation will be promptly addressed and may result in disciplinary action.</li>
                                        </ul>
                                        <h3>7. Record Keeping:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>Codevixens will maintain records of all grievances and their resolutions in accordance with applicable privacy laws and regulations.</li>
                                        </ul>
                                        <h3>8. Contact Information:</h3>
                                        <ul className="icon-list bullet-bg bullet-soft-navy">
                                            <li>Students can contact the <strong className='text-navy'>Programs Team at <a className='text-navy' href="mailto:programs@codevixens.org">programs@codevixens.org</a></strong> to initiate the formal grievance procedure or for assistance with any grievance-related matters.</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AdmissionPolicy;
