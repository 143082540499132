import React from 'react';
import check from '../../../../assets/img/team/check.svg';
import user from '../../../../assets/img/team/user.svg';
import briefcase from '../../../../assets/img/team/briefcase.svg';

import { team_members } from './teamMembers';

const TeamCards = () => {
    return (
        <section className="">
            <div className="container pt-8 pt-md-11">
                <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-15 align-items-center">
                    <div className="col-lg-5">
                        <h3 className="display-4 mb-3 ">
                            We are proud of our team of superheroes
                        </h3>
                        <p className="lead fs-lg mb-0">
                            We are a diverse group of people, this diversity is what makes us{' '}
                            <span className="underline">unique and talented</span>.
                        </p>
                    </div>

                    <div className="col-lg-7 mt-lg-2">
                        <div className="row align-items-center counter-wrapper gy-6 text-center">
                            <div className="col-md-4 col-4">
                                <img
                                    src={check}
                                    className="icon-svg icon-svg-lg text-primary mb-3"
                                    alt=""
                                />
                                <h3 className="counter">2</h3>
                                <p>Teams</p>
                            </div>

                            <div className="col-md-4 col-4">
                                <img
                                    src={user}
                                    className="icon-svg icon-svg-lg text-primary mb-3"
                                    alt=""
                                />
                                <h3 className="counter">5</h3>
                                <p>Happy Volunteers</p>
                            </div>

                            <div className="col-md-4 col-4">
                                <img
                                    src={briefcase}
                                    className="icon-svg icon-svg-lg text-primary mb-3"
                                    alt=""
                                />
                                <h3 className="counter">1866</h3>
                                <p>Community Members</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-16">
                {team_members.map((team) =>(
                <div className="col-md-6 col-lg-3 mb-10" key={team.id}>
                <div className="position-relative">
                    <div
                        className="shape rounded bg-soft-primary rellax d-md-block"
                        data-rellax-speed="0"
                        style={{
                            bottom: '-0.75rem',
                            right: '-0.75rem',
                            width: '98%',
                            height: '98%',
                            zIndex: 0
                        }}
                    ></div>
                    <div className="card shadow-lg " style={{minHeight: '400px' }}>
                        <figure className="card-img-top" >
                            <img className="img-fluid" src={team.pic} alt={team.name} />
                        </figure>
                        <div className="card-body px-6 py-5">
                            <h4 className="mb-1">{team.name}</h4>
                            <p className="mb-0">{team.position}</p>
                        </div>
                    </div>
                </div>
            </div>
                     ))}  
                </div>
            </div>
        </section>
    );
};

export default TeamCards;
