import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
   
    return (
        <>
            <section className='d-lg-flex align-items-center justify-content-center container'>
                <div className='py-4'>
                    <div
                        className='mt-lg-2 mt-5 pt-lg-2 mt-md-6 px-md-6 ps-lg-0 text-lg-center text-lg-start'>
                        <h1
                            className='display-1 mb-3 animation-default animation-delay-700 fw-light'
                            data-cue='slideInDown'
                            data-group='page-title'
                            data-delay='600'
                            data-show='true'>
                            Join Our Mission to Empower the Next Generation of Tech Talent
                        </h1>
                        <p
                            className='lead fs-16 mb-4 animation-default animation-delay-900 pe-7'
                            data-cue='slideInDown'
                            data-group='page-title'
                            data-delay='600'
                            data-show='true'>
                            Whether you're a seasoned professional or just starting your career, Codevixens offers a dynamic environment that fosters growth, creativity, and impact.
                            When you join our team, you’ll work on exciting projects, collaborate with industry experts, and have access to opportunities that drive personal and professional development. 
                        </p>
                        <div
                            className='d-flex justify-content-center justify-content-lg-center gap-2 flex-xl-row flex-column-reverse animation-default animation-delay-900'
                            data-cues='slideInDown'
                            data-group='page-title-buttons'
                            data-delay='900'
                            data-cue='slideInDown'
                            data-disabled='true'
                            data-show='true'>
                            <span
                                className='m-xl-0 mb-3 animation-default animation-delay-1500'
                                data-cue='slideInDown'
                                data-group='page-title-buttons'
                                data-delay='900'
                                data-show='true'>
                                <Link
                                    to='/about-us'
                                    className='btn btn-sm btn-outline-dark fs-15'>
                                    Who we are
                                </Link>
                            </span>

                            <span
                                className='animation-default animation-delay-1200'
                                data-cue='slideInDown'
                                data-group='page-title-buttons'
                                data-delay='900'
                                data-show='true'>
                                <Link
                                    to="/careers/open-positions"
                                    className='btn btn-sm btn-dark me-sm-2 fs-15'>
                                    View Open Positions
                                </Link>
                            </span>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default Hero;
