const imageUrl = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1734376908/website/5685973_ne5ya7.jpg';

const Hero = () => {
    return (
        <section className="py-8 py-md-8 mb-0">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                <div className="col-lg-6">
                    <figure className="rounded shadow-lg"><img src={imageUrl} alt="" /></figure>
                </div>

                <div className="col-lg-6">
                    <h2 className="mb-3 display-3"> Become a Skilled Database Administrator with Real-World Experience </h2>
                    <p className="lead">Are you ready to dive into the world of Database Administration? <br /> Join our Work Ready Program and gain hands-on experience managing and optimizing databases that power businesses across the globe.</p>
                    <button className="btn btn-dark btn-sm mt-2">Get Started</button>
                </div>

            </div>
        </section>
    );
};

export default Hero;