import React, { useEffect } from 'react';
import events from '../../../../assets/img/photos/events.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const EventsHero = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <>
            <section className='wrapper bg-gradient-primary bg-lines'>
                <div className='container py-10 py-sm-13 pb-lg-10 pt-lg-12'>
                    <div className='row'>
                        <div
                            className='col-lg-9'
                            data-aos='zoomIn'
                            data-aos-group='page-title'
                            data-aos-delay='500'
                            data-aos-show='true'>
                            <span className="badge bg-navy text-white rounded-pill mb-2">Events</span>
                            <h3 className='mb-0'>Join Our Codevixens Events</h3>
                        <p className="mt-2 mb-4">
                        Calling all Software Developers, UX/UI Designers, Project Managers, as well as CTOs and CPOs: we have an event for you. At Codevixens, we love tackling complex coding challenges and discussing innovative solutions with industry leaders. Our events range from deeply technical workshops to high-level strategic discussions.
                        </p>

                            <div
                                className='mb-lg-0 mb-10'
                                data-aos='zoomIn'
                                data-aos-group='page-title-buttons'
                                data-aos-delay='900'>
                                <span>
                                    <a
                                        href='#details'
                                        className='btn btn-sm btn-navy me-sm-2 fs-13'>
                                        View Upcoming Events
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EventsHero;
