export const events_details = [
    {img: '../../../../assets/img/photos/b10.jpg',
     event_name: "Academy Training Band 4.0 - Alumni Session",
     event_date: "18th December 2024",
     event_time: "2024-11-20",
     id:0,
     event_timeline: "Future Event"
    },
    {img: '../../../../assets/img/photos/b10.jpg',
        event_name: "Academy Training Band 4.0 - Alumni Session",
        event_date: "18th December 2024",
        event_time: "2024-11-20",
        id:1,
        event_timeline: "Future Event"
    },
    {img: '../../../../assets/img/photos/b10.jpg',
        event_name: "Academy Training Band 4.0 - Alumni Session",
        event_date: "18th December 2024",
        event_time: "2024-11-20",
        id:2,
        event_timeline: "Future Event"
    },
    {img: '../../../../assets/img/photos/b10.jpg',
        event_name: "Academy Training Band 4.0 - Alumni Session",
        event_date: "18th December 2024",
        event_time: "2024-11-20",
        id:3,
        event_timeline: "Future Event"
    },
    {img: '../../../../assets/img/photos/b10.jpg',
        event_name: "Academy Training Band 4.0 - Alumni Session",
        event_date: "18th December 2024",
        event_time: "2024-11-20",
        id:4,
        event_timeline: "Past Event"
    },
    {img: '../../../../assets/img/photos/b10.jpg',
        event_name: "Academy Training Band 4.0 - Alumni Session",
        event_date: "18th December 2024",
        event_time: "2024-11-20",
        id:5,
        event_timeline: "Past Event"
    }
]