import Carousel from "./Carousel";

const Schools = () => {
    return (
        <section>
            <div className="row pt-4 mt-0 mb-3">
                <div className="col-lg-9 col-xl-8 col-xxl-7">
                    <h2 className="text-line mb-3 text-navy lead fs-md fw-bold">Our Academy Schools</h2>
                </div>
            </div>
            <Carousel />
        </section>
    );
}

export default Schools;