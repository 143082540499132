import React from 'react';
import Hero from './includes/Hero'; 
import Calendar from './includes/Calendar';

const AcademicCalendar = () => {
    return (
        <>
            <section className="container wrapper my-10">
                <Hero />
                <Calendar />
            </section>
        </>
    );
};

export default AcademicCalendar;
