import React from 'react';

const Testimonials = () => {
    return (
        <div id='testimonials-page'>
            <section className="wrapper mt-13">
                <div className='container'>
                    {/* <div className="row gx-lg-8 gx-xl-0 align-items-center">
                        <div className="col-lg-5 col-xl-4 offset-xl-1 d-none d-lg-flex position-relative">
                            <div className="shape rounded-circle bg-gray rellax w-21 h-21" data-rellax-speed="1" style={{ top: '7rem', left: '1rem' }}></div>
                            <figure><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1714177314/website/2148666492_Background_Removed_oyswmd.png" srcSet="https://res.cloudinary.com/dar3e312jder/image/upload/v1714177314/website/2148666492_Background_Removed_oyswmd.png 2x" alt="" /></figure>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-xxl-5 offset-xl-1">
                            <div className="swiper-container dots-start dots-closer mt-6 mb-6" data-margin="30" data-dots="true">
                                <div className="swiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <blockquote className="icon fs-lg">
                                                <p className="fs-18">“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio consectetur nulla dapibus curabitur blandit faucibus.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-1">Coriss Ambady</h5>
                                                        <p className="mb-0">Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                        <div className="swiper-slide">
                                            <blockquote className="icon fs-lg">
                                                <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio consectetur nulla dapibus curabitur blandit faucibus.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-1">Cory Zamora</h5>
                                                        <p className="mb-0">Marketing Specialist</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                        <div className="swiper-slide">
                                            <blockquote className="icon fs-lg">
                                                <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum ligula porta felis euismod semper. Cras justo odio consectetur nulla dapibus curabitur blandit faucibus.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-1">Nikolas Brooten</h5>
                                                        <p className="mb-0">Sales Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-md-10">
                            <h3 className="display-3 mb-10">See what our <br /> Alumnis say about us</h3>
                        </div>
                    </div>
                    <div className='d-lg-flex pb-10'>
                        <div className='col-lg-4 pe-4'>
                            <div className="player rounded-0" data-plyr-provider="youtube" data-plyr-embed-id="nb8ulzBMb5Q"></div>
                        </div>
                        <div className='col-lg-4 ps-4'>
                            <div className="player rounded-0 col-lg-6" data-plyr-provider="youtube" data-plyr-embed-id="jl7n-AyUpSQ"></div>
                        </div>
                        <div className='col-lg-4 ps-4'>
                            <div className="player rounded-0 col-lg-6" data-plyr-provider="youtube" data-plyr-embed-id="Vnu0JRwW7Y8"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid col-md-12 mb-lg-15">
                            <div className="row isotope gy-6">
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Coriss Ambady</h5>
                                                        <p className="mb-0 fs-15">Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">“Fusce dapibus, tellus ac cursus tortor mauris condimentum fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Cory Zamora</h5>
                                                        <p className="mb-0 fs-15">Marketing Specialist</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">“Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum. Donec sed odio dui consectetur.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Nikolas Brooten</h5>
                                                        <p className="mb-0 fs-15">Sales Manager</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">“Etiam adipiscing tincidunt elit convallis felis suscipit ut. Phasellus rhoncus tincidunt auctor. Nullam eu sagittis mauris. Donec non dolor ac elit aliquam tincidunt at at sapien.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Coriss Ambady</h5>
                                                        <p className="mb-0 fs-15">Financial Analyst</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">“Maecenas sed diam eget risus varius blandit sit amet non magna. Cum sociis natoque penatibus magnis dis montes, nascetur ridiculus mus. Donec sed odio dui.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Jackie Sanders</h5>
                                                        <p className="mb-0 fs-15">Investment Planner</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                                <div className="item col-md-6 col-xl-4">
                                    <div className="card shadow-lg">
                                        <div className="card-body">
                                            {/* <span className="ratings five mb-3"></span> */}
                                            <blockquote className="icon mb-0">
                                                <p className="fs-15 mb-2">“Donec id elit non mi porta gravida at eget metus. Nulla vitae elit libero, a pharetra augue. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur.”</p>
                                                <div className="blockquote-details">
                                                    <div className="info ps-0">
                                                        <h5 className="mb-0 fs-17">Laura Widerski</h5>
                                                        <p className="mb-0 fs-15">Sales Specialist</p>
                                                    </div>
                                                </div>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Testimonials;