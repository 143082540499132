import React, { useState, useEffect } from 'react';
import Overview from './includes/FooterCTA';
import Hero from './includes/Hero';
import Introduction from './includes/Introduction';
import Schools from './includes/Schools';
import UpcomingBatches from './includes/UpcomingBatches';
import FooterCTA from './includes/FooterCTA';

const Academy = () => {
    return (
        <section className="wrapper container">
            <Hero />
            <Introduction />
            <Schools />
            <UpcomingBatches />
            <FooterCTA />
        </section>
    );
}
 
export default Academy;