import React, { useContext } from 'react';
import { tutors_info } from '../../../constants';
import { ReactComponent as Calendar } from '../../../assets/img/icons/lineal/calendar.svg';
import { ReactComponent as Globe } from '../../../assets/img/icons/lineal/geolocalization.svg';
import { ReactComponent as Clock } from '../../../assets/img/icons/lineal/clock.svg';
import { ModalContext } from '../../../utils/contexts';
import EventsImages from './includes/Carousel';

function EventDetails() {
    const { setOpenModal } = useContext(ModalContext);
    const Carousel = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1694267233/website/women-happy.jpg';
    const imageUrls = [Carousel, Carousel, Carousel, Carousel];

    return (
        <>
            <div className="container pb-4 pb-xl-4 pb-md-16">
                <section className="mb-8 mx-auto pt-xl-5 background-image rounded">
                    <EventsImages imageUrls={imageUrls} />
                </section>
                <section className="py-1 mx-auto0" style={{ 'position': 'relative' }}>
                    <div className="row gx-0 gx-lg-8 gx-xl-12 gy-10 align-items-start justify-content-between">
                        <div className="col-lg-7 text-left ml-auto">
                            <div className="row gy-3 mb-2">
                                <div className="col-xl-5">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li className='px-1 location-img d-flex flex-row'>
                                            <Calendar />
                                            <div>
                                                <h6 className='mb-0'>Date</h6>
                                                <p className='mb-0'>Thursday, August 10</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-4">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li className='px-1 location-img d-flex flex-row align-items-center'>
                                            <Clock />
                                            <div>
                                                <h6 className='mb-0'>6pm - 8pm WAT</h6>
                                                <p className='mb-0'>2 Hours</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-3">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li className='px-1 location-img d-flex flex-row'>
                                            <Globe />
                                            <div>
                                                <h6 className='mb-0'>Location</h6>
                                                <p className='mb-0'>Virtual</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <h2 className="display-2">Women&apos;s International Film Festival - Bonus Virtual Day 1</h2>
                            <div className='mx-auto mb-4'>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Community Hour </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Twitter Spaces </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Training Band </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> IWD </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Graduation </p>
                                </div>
                            </div>

                            <p className='mb-10'>Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer posuere erat a ante venenatis. Etiam porta sem malesuada magna mollis euismod. Aenean lacinia bibendum.</p>

                            <h3 className="display-6 mb-4">About this event</h3>
                            <p>This Pitch Fest will take place virtually using Zoom Webinars. Once you register you will receive the Zoom link to access the meeting!</p>
                            <p>To access the meeting it is recommended to use the link to Zoom instead of viewing from the Eventbrite website.</p>

                            <h3 className="display-6 text-uppercase mt-10 mb-5"> Event Speakers </h3>
                            <div className="position-relative">
                                <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
                                    {tutors_info.map((person, index) => {
                                        if (index <= 1) {
                                            return (
                                                <div className="col-md-6 col-lg-5 mb-5 pb-5" key={index}>
                                                    <div className="position-relative">
                                                        <div className="shape rounded bg-soft-blue rellax d-md-block" data-rellax-speed="0" style={{ "bottom": "-0.75rem", "right": "-0.75rem", "width": "98%", "height": "98%", "zIndex": "0" }}></div>
                                                        <div className="card">
                                                            <figure className="card-img-top">
                                                                <img className="img-fluid speaker-img" src={person.img} srcSet={`${person.img} 2x`} alt="" />
                                                            </figure>
                                                            <div className="card-body px-6 py-5">
                                                                <h4 className="mb-1">{person.name}</h4>
                                                                <p className="mb-0">{person.track}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-xl-4 px-3 ms-auto sticky-component'>
                            <div className="card card-body p-3 col-md-15 mx-0">
                                <div className="row px-3 justify-content-between align-items-center mb-5">
                                    <h6 className='d-inline col-4 fs-15 p-0'> Attendee </h6>
                                    <span className='d-inline-flex flex-row justify-content-end p-0 col-4 align-items-center'>
                                        <h6 className='my-0 fs-20 mx-3'> 1 </h6>
                                    </span>
                                </div>
                                <button className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        setOpenModal(true);
                                        document.body.classList.add('modal-open');
                                    }}
                                > Register
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default EventDetails;