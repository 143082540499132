import React, { useEffect, useMemo, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import ErrorPage from './components/ErrorPage';
import ScrollToTop from './components/includes/ScrollToTop';
import Home from './components/pages/home/Home';
import Team from './components/pages/team/Team';
import { ModalContext } from './utils/contexts';
import Academy from './components/pages/academy/Academy';
import Footer from './components/includes/Footer';
import Header from './components/includes/Header';
import Support from './components/includes/Support';
import Events from './components/pages/events/Events';
import AboutUs from './components/pages/about/AboutUs';
import WorkReadyProgram from './components/pages/work-ready-program/WorkReadyProgram';
import Testimonials from './components/pages/Testimonials';
import Community from './components/pages/community/Community';
import AdmissionPolicy from './components/pages/admissions/Policy';
import AdmissionProcess from './components/pages/admissions/Process';
import EventDetails from './components/pages/event-details/EventDetails';
import ScrollToHashElement from './components/includes/ScrollToHashElement';
import FinancialAssistance from './components/pages/about/FinancialAssistance';
import AcademySchools from './components/pages/academy-schools/AcademySchools';
import DataEngineeringSchool from './components/pages/academy-schools/DataEngineeringSchool';
import CloudDevOpsSchool from './components/pages/academy-schools/CloudDevOpsSchool';
import EventRegistration from './components/pages/event-details/includes/EventRegistration';
import OpenPositions from './components/pages/open-positions/OpenPositions';
import OpenPosition from './components/pages/open-position/OpenPosition';
import DatabaseAdministration from './components/pages/wrp-career-paths/database-administration/DatabaseAdministration';
import PrivacyPolicy from './components/pages/PrivacyPolicy';

import './assets/scss/style.scss';
import WorkingAtCodeVixens from './components/pages/working-at-codevixens/WorkingAtCodeVixens';
import RegistrationForm from './components/pages/work-ready-program/RegistrationForm';
import AcademicCalendar from './components/pages/academic-calendar/AcademicCalendar';


function setExternalScripts(url) {
    url.forEach((externalScript, key) => {
        const script = document.createElement('script');
        script.src = externalScript.url;
        externalScript.attribute === 'async'
            ? (script.async = true)
            : (script.defer = true);
        document.body.appendChild(script);
    });
}

function App() {
    //Context for registration modal
    const [openModal, setOpenModal] = useState(false),

        modalValue = useMemo(() => ({ openModal, setOpenModal }), [openModal, setOpenModal]);

    useEffect(() => {
        const onPageLoad = () => {
            setExternalScripts([{ url: '/assets/js/theme.js', attribute: 'defer' }]);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            <ModalContext.Provider value={modalValue}>
                <EventRegistration />

                <div className="content-wrapper">
                    <Header />
                    <ScrollToTop />
                    <ScrollToHashElement />

                    <Routes>
                        <Route
                            element={<Home />}
                            path="/"
                        />

                        <Route
                            element={<AcademicCalendar />}
                            path="/academic-calendar"
                        />

                        <Route
                            element={<Team />}
                            path="/team"
                        />

                        <Route
                            element={<WorkingAtCodeVixens />}
                            path="/careers/working-at-codevixens"
                        />

                        <Route
                            element={<OpenPositions />}
                            path="/careers/open-positions"
                        />

                        {/* <Route
                        element={<OpenPosition />}
                        path="/careers/open-positions/:id"
                    />  */}

                        <Route
                            element={<WorkReadyProgram />}
                            path="/programs/work-ready-program"
                        />

                        <Route
                            element={<RegistrationForm />}
                            path="/programs/work-ready-program/cohorts/cohort-1"
                        />

                        <Route
                            element={<DatabaseAdministration />}
                            path="/programs/work-ready-program/career-paths/database-administration"
                        />

                        <Route
                            element={<Testimonials />}
                            path="/testimonials"
                        />

                        <Route
                            element={<FinancialAssistance />}
                            path="/financial-assistance"
                        />

                        <Route
                            element={<AdmissionPolicy />}
                            path="/admissions-policy"
                        />

                        <Route
                            element={<AdmissionProcess />}
                            path="/admissions-process"
                        />

                        <Route
                            element={<AboutUs />}
                            path="/about-us"
                        />

                        <Route
                            element={<Academy />}
                            path="/academy-program"
                        />

                        <Route
                            element={<AcademySchools />}
                            path="/academy-program/schools"
                        />

                        <Route
                            element={<DataEngineeringSchool />}
                            path="/academy-program/schools/data-engineering-school"
                        />

                        <Route
                            element={<CloudDevOpsSchool />}
                            path="/academy-program/schools/cloud-devops-school"
                        />

                        <Route
                            element={<Community />}
                            path="/community"
                        />

                        <Route
                            element={<Events />}
                            path="/events"
                        />

                        <Route
                            element={<EventDetails />}
                            path="/events/1"
                        />

                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                        <Route
                            element={<ErrorPage />}
                            path="*"
                        />
                    </Routes>
                </div>

                <Support />

                <Footer />
            </ModalContext.Provider>

            {setExternalScripts([
                { url: '/assets/js/plugins.js', attribute: 'defer' },
            ])}
        </>
    );


}

export default App;
