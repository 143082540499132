import React from 'react';
import TeamCards from './includes/TeamCards';
// import TeamHero from './includes/TeamHero';
// import TeamFacts from './includes/TeamFacts';

const Team = () => {
    return (
        <>
            {/* <TeamFacts /> */}
            <TeamCards />
        </>
    );
};

export default Team;
