import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import PropTypes from 'prop-types';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

const EventsImages = ({ imageUrls }) => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay]}
                className="event-swiper"
            >
                {
                    imageUrls.map((img, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                <img className='object-fit-cover' src={img} alt={`Event images ${idx}`} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    );
};

EventsImages.propTypes = {
    imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EventsImages;