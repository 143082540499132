import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Calendar } from '../../../assets/img/icons/lineal/calendar.svg';
import { ReactComponent as Alarm } from '../../../assets/img/icons/solid/alarm.svg';

const WorkReadyProgram = () => {
    return (
        <div className='container' id='fellowship-page'>
            <div className='row'>
                <div className="mt-0 mb-4 pt-10 pt-xxl-10 col-lg-7 pt-lg-10" data-cues="slideInDown" data-group="page-title" data-disabled="true">
                    <h1 className="display-1 ls-xs fs-40 mb-4" data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>You've Learnt Enough.  <br />Let's Get YOU <span className="underline-3 style-2 yellow"> Work-Ready</span>.</h1>
                    <p className="lead mb-2 me-lg-16" data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                        <em>Employers want candidates with <b>experience</b>, <b>not just skills</b>.</em> <br />
                        Join our Work Ready Program to fill your resume with real-world tech projects.
                    </p>
                    <ul className="icon-list bullet-bg bullet-soft-navy mb-2" data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                        <li className='mt-0 me-3 d-inline-flex'><span><i className="uil uil-check"></i></span><span>Cloud Engineering</span></li>
                        <li className="mt-0 me-3 d-inline-flex"><span><i className="uil uil-check"></i></span><span>Software Engineering</span></li>
                        <li className="mt-0 me-3 d-inline-flex"><span><i className="uil uil-check"></i></span><span>DevOps Engineering</span></li>
                        <li className="mt-0 me-3 d-inline-flex"><span><i className="uil uil-check"></i></span><span>Database Administration</span></li>
                    </ul>
                    <div className='mb-4' data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                        <div className='mb-0'><Calendar className="svg-inject icon-svg icon-svg-xs solid-mono text-dark me-2" style={{ width: '1.3rem' }} /> New Cohort Starts January 20th 2025</div>
                        <div><Alarm className="svg-inject icon-svg icon-svg-xs solid-mono text-dark me-2" style={{ width: '1.3rem' }} /> <b>Program Duration:</b> 12 Weeks</div>
                    </div>
                    {/* <div className="row gx-md-5 gy-5 text-center mt-0 me-16" data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}><div className="col-md-6 col-xl-3"><div className="card card-border-end border-soft-dark"><div className="card-body text-start px-6 py-4"><h6>Affordable Tech Education ✅</h6></div></div></div><div className="col-md-6 col-xl-3"><div className="card card-border-end border-soft-dark"><div className="card-body text-start px-6 py-4"><h6>Certificate of Completion ✅</h6></div></div></div><div className="col-md-6 col-xl-3"><div className="card card-border-end border-soft-dark"><div className="card-body text-start px-6 py-4"><h6>Quality Training ✅</h6></div></div></div><div className="col-md-6 col-xl-3"><div className="card card-border-end border-soft-dark"><div className="card-body text-start px-6 py-4"><h6>Experienced Instructors ✅</h6></div></div></div></div> */}
                    <div data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                        <Link className='btn btn-sm btn-dark rounded mb-2' to={'/programs/work-ready-program/cohorts/cohort-1'}>Join Cohort 1 Now</Link>
                    </div>
                </div>
                <div className="col-lg-5 pe-0">
                    <div className="my-6 row gx-md-5 gy-5 me-3">
                        <div className="col-md-6">
                            <figure className="rounded"><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734390179/website/8553950_s9alhx.jpg" alt="" /></figure>
                        </div>
                        <div className="col-md-6 align-self-end">
                            <figure className="rounded"><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1734391077/website/6150527-2_bxytyi.jpg" alt="" /></figure>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mb-15 pt-0'>
                <div className="card mt-8 bg-soft-dark card-border-start border-dark">
                    <div className="card-body">
                        <span className="ratings five mb-3"></span>
                        <blockquote className="icon mb-0">
                            <p>“Codevixens has trained over 3,500 individuals globally, empowering them to break into high-demand tech fields. With a focus on practical experience and personalized mentorship, our programs have a proven track record of preparing participants for successful tech careers.”</p>
                            <Link className='hover-1 more text-dark' to={'/about-us'}>Learn More</Link>
                        </blockquote>
                    </div>
                </div>

                <p className='mb-2 mt-8'>Our Work Ready Program bridges the gap between technical skills and career readiness. It is designed for aspiring tech professionals who already have a foundational understanding, this 12-week program provides real-world projects, mentorship, and the tools you need to thrive in your career.</p>

                <ul className="icon-list bullet-bg bullet-soft-navy mb-0">
                    <li><span><i className="uil uil-check"></i></span><span>4 Specialized Career Tracks (Database Administration, Software Engineering, DevOps Engineering, Cloud Engineering).</span></li>
                    <li className="mt-2"><span><i className="uil uil-check"></i></span><span>Real-world project experience in a collaborative environment.</span></li>
                    <li className="mt-2"><span><i className="uil uil-check"></i></span><span>Weekly projects, assessments and feedback to help you improve.</span></li>
                    <li className="mt-2"><span><i className="uil uil-check"></i></span><span>Certification of completion to showcase your expertise.</span></li>
                </ul>

                <div className="row mt-7 pt-6">
                    <div className="col-lg-8 col-xl-7 col-xxl-6">
                        <h2 className="fs-15 text-uppercase text-muted mb-1">Program Courses</h2>
                        <h4 className="mb-6">The Career Paths We Offer</h4>
                    </div>
                </div>
                
                <div className="row gx-md-8 gy-8 pb-5 mb-4">
                    <div className="col-md-6 col-lg-3">
                        <div className="icon btn btn-block btn-lg btn-soft-navy pe-none mb-5"> <i className="uil uil-database"></i> </div>
                        <h4>Database Administration</h4>
                        <p className="mb-3">Work on optimizing database performance, designing scalable schemas, and managing backup solutions in live environments.</p>
                        {/* <Link className="more hover link-cdv-alt" to="/programs/work-ready-program/career-paths/database-administration">Learn More</Link> */}
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="icon btn btn-block btn-lg btn-soft-navy pe-none mb-5"> <i className="uil uil-university"></i> </div>
                        <h4>Software Engineering</h4>
                        <p className="mb-3">Collaborate on building scalable web applications, writing/consuming clean APIs, and solving complex coding challenges.</p>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="icon btn btn-block btn-lg btn-soft-navy pe-none mb-5"> <i className="uil uil-server"></i> </div>
                        <h4>DevOps Engineering</h4>
                        <p className="mb-3">Set up CI/CD pipelines, automate deployments, and improve infrastructure reliability.</p>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="icon btn btn-block btn-lg btn-soft-navy pe-none mb-5"> <i className="uil uil-cloud-computing"></i> </div>
                        <h4>Cloud Engineering</h4>
                        <p className="mb-3">Deploy and manage cloud-based systems, optimize costs, and ensure high availability for large-scale applications.</p>
                    </div>
                </div>

                <section className='pb-5 mb-4'>
                    <h3 className="lead text-line fw-bold mt-1">Participant Eligibility Criteria</h3>
                    <p className='mb-6'>Participants must meet the following criteria to be eligible for the program:</p>

                    <div className="d-flex flex-row mb-6"><div><span className="icon btn btn-block btn-soft-navy pe-none me-5"><span className="number fs-18">1</span></span></div><div>
                        <h4 className="mb-1 fs-17 fw-bolder">Completion of Prerequisite Courses:</h4>
                        <p className="mb-0">Participants should have completed foundational technical training in relevant areas (e.g., coding, cloud, database management).</p></div></div>
                    <div className="d-flex flex-row mb-6"><div><span className="icon btn btn-block btn-soft-navy pe-none me-5"><span className="number fs-18">2</span></span></div><div><h4 className="mb-1 fs-17 fw-bolder">Basic Technical Proficiency:</h4><p className="mb-0">Demonstrated during the application process.</p></div></div>
                </section>

                <section className='pt-0'>
                    <h2 className="text-line mb-1 text-navy lead fs-md fw-bold">Upcoming Cohorts</h2>
                    <p className='mb-5'>Don’t miss out on your opportunity to gain real-world experience. Explore our next sessions and reserve your spot.</p>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="bg-pale-primary">
                                <th scope="col" colSpan="2">2025 - Cohort 1</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td className='py-2' colSpan={2}>Application for this program starts on the <b>17th of December 2024</b> and ends on the <b>15th of January 2025</b>. <br />The program officially commences on the <b>20th of January 2025</b>.</td></tr>
                            <tr>
                                <td className='py-2'>Duration:&nbsp;</td>
                                <td className='py-2'><div className='badge bg-dark'>12 weeks</div></td>
                            </tr>
                            <tr>
                                <td>What You'll Gain</td>
                                <td className='py-2'>
                                    <ul className="icon-list mb-2">
                                        <li className="mb-1"><span><i className="uil uil-check"></i></span><span>Real-world project experience.</span></li>
                                        <li className="mb-1"><span><i className="uil uil-check"></i></span><span>Weekly feedback and reviews.</span></li>
                                        <li className="mb-1"><span><i className="uil uil-check"></i></span><span>Collaboration with other professionals.</span></li>
                                        <li className="mb-1"><span><i className="uil uil-check"></i></span><span>Certificate of completion and many more...</span></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className='py-2'>Limited Slots:</td>
                                <td className='py-2'>
                                    <Link className='btn btn-sm btn-dark rounded' to={'/programs/work-ready-program/cohorts/cohort-1'}>Apply Now</Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <section className="wrapper bg-light">
                    <div className="container pt-10">
                        <h2 className="fs-15 text-uppercase text-muted mb-1">Program Costs</h2>
                        <h4 className="mb-0">Flexible Options for Every Level</h4>
                        <div className="alert alert-info alert-icon mb-0 mt-5" role="alert"><i className="uil uil-money-bill"></i> We have available <b>payment options</b> for various currencies, including: <b>GHS</b>, <b>KES</b>, <b>USD</b>, <b>EUR</b> etc.</div>
                        <div className="pricing-wrapper">
                            <div className="row gx-0 gy-6 mt-0">
                                <div className="col-md-6 col-lg-3">
                                    <div className="pricing card">
                                        <div className="card-body">
                                            <div className="prices text-dark mb-1">
                                                <div className="price price-show justify-content-start">
                                                    <span className="price-currency">₦</span>
                                                    <span className="price-value ls-sm fw-bolder">5,000</span>
                                                </div>
                                            </div>
                                            <h4 className="card-title">Exploratory Tier</h4>
                                            <p className="fs-14"><b>Description:</b> Foundational experience and exposure to projects. Ideal for beginners looking to understand the program basics.</p>
                                            <ul className="icon-list bullet-bg bullet-soft-navy my-0">
                                                <li><i className="uil uil-check"></i><span><strong>Duration:</strong>&nbsp;3 days</span></li>
                                                <li><i className="uil uil-check"></i><span><strong>1</strong> Project </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>Basic</strong>&nbsp;project exposure</span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> Weekly <strong>Mentorship</strong> </span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span>One-on-One<strong>&nbsp;Mentorship</strong> </span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span>Career-ready Certification</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="pricing card">
                                        <div className="card-body">
                                            <div className="prices text-dark mb-1">
                                                <div className="price price-show justify-content-start">
                                                    <span className="price-currency">₦</span>
                                                    <span className="price-value ls-sm fw-bolder">17,000</span>
                                                </div>
                                            </div>
                                            <h4 className="card-title">Basic Tier</h4>
                                            <p className="fs-14"><b>Description:</b> Dive deeper into your specialization and gain hands-on experience.</p>
                                            <ul className="icon-list bullet-bg bullet-soft-navy my-0">
                                                <li><i className="uil uil-check"></i><span><strong>Duration:</strong>&nbsp;3 weeks</span></li>
                                                <li><i className="uil uil-check"></i><span><strong>6</strong> Projects </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>Mid-Level</strong> Project Exposure </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>Weekly</strong> Mentorship </span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> <strong>Group</strong> Projects</span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> One-on-one <strong>Mentorship</strong></span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> Career-ready Certification</span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> Career support and portfolio reviews</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="pricing card">
                                        <div className="card-body">
                                            <div className="prices text-dark mb-1">
                                                <div className="price price-show justify-content-start">
                                                    <span className="price-currency">₦</span>
                                                    <span className="price-value ls-sm fw-bolder">25,000</span>
                                                </div>
                                            </div>
                                            <h4 className="card-title">Premium Tier</h4>
                                            <p className="fs-14"><b>Description:</b> Refine your skills with extended mentorship and impactful projects.</p>
                                            <ul className="icon-list bullet-bg bullet-soft-navy my-0">
                                                <li><i className="uil uil-check"></i><span><strong>Duration:</strong> 6 weeks </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>12</strong> Projects </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>2 Group</strong> Projects </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>Advanced-Level</strong> Project Exposure </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>Weekly</strong> Mentorship </span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span>Certificate of Completion</span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> One-on-one <strong>Mentorship</strong></span></li>
                                                <li><i className="uil uil-times bullet-soft-red"></i><span> Career support and portfolio reviews</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="pricing card bg-soft-navy">
                                        <div className="card-body">
                                            <div className="prices text-dark mb-1">
                                                <div className="price price-show justify-content-start">
                                                    <span className="price-currency">₦</span>
                                                    <span className="price-value ls-sm fw-bolder">40,000</span>
                                                </div>
                                            </div>
                                            <h4 className="card-title">Mastery Tier</h4>
                                            <p className="fs-14"><b>Description:</b> The ultimate work-ready experience with in-depth mentorship, complex projects, and career development.</p>
                                            <ul className="icon-list bullet-bg bullet-navy my-0">
                                                <li><i className="uil uil-check"></i><span><strong>Duration:</strong> 12 weeks </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>24</strong> Projects </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>12</strong> Group Projects </span></li>
                                                <li><i className="uil uil-check"></i><span> <strong>Mastery-Level</strong> Project Exposure</span></li>
                                                <li><i className="uil uil-check"></i><span><strong>Weekly</strong> Mentorship </span></li>
                                                <li><i className="uil uil-check"></i><span><strong>2</strong> Certifications</span></li>
                                                <li><i className="uil uil-check"></i><span> One-on-one <strong>Mentorship</strong></span></li>
                                                <li><i className="uil uil-check"></i><span> Career support and portfolio reviews</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="snippet-3" className="">
                    <div className="pt-15">
                        <h4 className="">Program FAQs</h4>
                        <ul className="nav nav-tabs nav-tabs-basic">
                            <li className="nav-item"> <a className="nav-link active" data-bs-toggle="tab" href="#tab3-1">Eligibility and Application</a> </li>
                            <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab3-2">Program Structure and Expectations</a> </li>
                            <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab3-3">Fees and Certification</a> </li>
                            <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab3-4">Post-Program Benefits</a> </li>
                        </ul>
                        <div className="tab-content mt-0 mt-md-5">
                            <div className="tab-pane fade show active" id="tab3-1">
                                <div className='row pt-0'>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Who is this program for?</h5>
                                                <p className="mb-0 fs-15">The Work Ready Program is designed for individuals with foundational knowledge in database administration, software engineering, DevOps engineering, or cloud engineering. It’s for those who want to gain real-world experience and job-ready skills.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Do I need prior experience to join?</h5>
                                                <p className="mb-0 fs-15">Yes, this program is not for beginners. You’ll need to demonstrate your understanding of the fundamentals during the application process.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Is there an application fee?</h5>
                                                <p className="mb-0 fs-15">No, applying is free, but you’ll need to pay the program fee upon acceptance to secure your spot.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>How do I know if I’m ready for the program?</h5>
                                                <p className="mb-0 fs-15">If you’ve completed training or have prior experience in one of the four career paths and can confidently work on real-world tasks, you’re ready for this program.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab3-2">
                                <div className='row pt-0'>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Is the program fully remote?</h5>
                                                <p className="mb-0 fs-15">Yes, it’s entirely remote, so you can participate from anywhere with a reliable internet connection.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>How many hours do I need to commit weekly?</h5>
                                                <p className="mb-0 fs-15">You’ll need to dedicate 20–25 hours per week, depending on the complexity of your track and your pace.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>What projects will I work on?</h5>
                                                <p className="mb-0 fs-15">You’ll work on real-world, portfolio-worthy projects tailored to your chosen career path. These simulate the challenges professionals face in actual tech environments.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Will I receive mentorship?</h5>
                                                <p className="mb-0 fs-15">Yes, you’ll have access to guidance and feedback from industry professionals throughout the program.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab3-3">
                                <div className='row pt-0'>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>What is the program fee?</h5>
                                                <p className="mb-0 fs-15">The program fee is tier based - check the "Program Costs" section for the pricing. This fee covers access to all resources, mentorship, and your certification.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Do I get a certification?</h5>
                                                <p className="mb-0 fs-15">Yes, upon successfully completing the program, you’ll receive a certificate of completion to showcase your work-ready skills.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Are there scholarships or financial aid options?</h5>
                                                <p className="mb-0 fs-15">Yes, we offer financial assistance through partnerships. Contact us ny mail at <Link to={'mailto:program@codevixens.org'}>program@codevixens.org</Link> to learn more.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab3-4">
                                <div className='row pt-0'>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>Will I get a job after completing the program?</h5>
                                                <p className="mb-0 fs-15">While the program doesn’t guarantee a job, it equips you with the experience and confidence to succeed in interviews and the workplace.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-3 pb-2'>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <span className="icon btn btn-sm btn-circle btn-navy pe-none me-5"><i className="uil uil-comment-exclamation"></i></span>
                                            </div>
                                            <div>
                                                <h5 className='mb-1'>What support will I get after the program?</h5>
                                                <p className="mb-0 fs-15">You’ll have access to resources for job hunting, including interview tips, resume reviews, and networking opportunities.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default WorkReadyProgram;