import React from 'react';
import { faqs } from "../../../../constants";
import { BsDash } from "react-icons/bs";

const WhoCanJoin = () => {
    return (
        <section className="wrapper mb-10 mb-md-13">
            <div className="container py-14 py-md-10">
                <div className="row mb-2">
                    <div className="col-lg-9 col-xl-8 col-xxl-7">
                        <p className='text-primary'><BsDash />FAQS</p>
                        <h3 className="display-3 mb-md-5">You may wonder about the following about our online community</h3>
                    </div>
                </div>
                <div className="row gx-md-5">
                    {
                        faqs.map((info, index) => (
                            <div className="col col-md-6 col-xl-6" key={index} data-aos="fade-down"
                                data-aos-delay={`${(index + 1) * 400}`} data-aos-once="false" data-aos-duration='900'>
                                <div className="card-body d-flex flex-row">
                                    <div className="icon btn btn-circle btn-sm bg-soft-primary"> <i className={`text-primary uil uil-${info.icon} w-15`}></i> </div>
                                    <div className='mx-2'>
                                        <h4 className="mb-3">{info.quest}</h4>
                                        <p>{info.ans}</p>
                                    </div>

                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default WhoCanJoin;