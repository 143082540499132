const WhoWeAre = () => {
    const images = [
        {
            height: '220px',
            img: 'https://res.cloudinary.com/dar3e312jder/image/upload/v1697604472/website/IMG_1232_nrhx5n.jpg',
            classes: 'col-md-6'
        },
        {
            height: '190px',
            img: 'https://res.cloudinary.com/dar3e312jder/image/upload/v1697604379/website/IMG_1214_erhz5w.jpg',
            classes: 'col-md-6 align-self-end'
        },
        {
            height: '239px',
            img: 'https://res.cloudinary.com/dar3e312jder/image/upload/v1697604140/website/IMG_1031_p5zyow.jpg',
            classes: 'col-12'
        }
    ]

    return (
        <section id="snippet-13" className="wrapper bg-light mt-10">
            <div className="container pb-13 pb-md-15">
                <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center mb-0">
                    <div className="col-lg-12">
                        <h2 className="fs-16 text-uppercase text-primary mb-3">Who Are We?</h2>
                        <h3 className="display-3 mb-5">We Empower Individuals Globally Through Affordable, Hands-On Tech Education.</h3>
                        <p className="mb-2">At Codevixens, we're passionate about empowering individuals through tech education. We offer affordable, high-quality programs designed to equip you with the skills and knowledge needed to thrive in the ever-evolving tech industry.</p>
                        <p className="mb-0">Our mission is twofold: to <strong className="text-navy">empower individuals</strong> through accessible education, and to <strong className="text-navy">foster inclusivity</strong> in the tech space. We believe that everyone, regardless of gender or background, deserves the opportunity to learn and excel in this dynamic field.</p>
                    </div>
                </div>
                <div className="row py-10">
                    <div className="col-xl-10 col-xxl-9 me-auto">
                        <div className="row align-items-center justify-content-center counter-wrapper gy-6">
                            <div className="col-md-6 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-row align-items-center">
                                            <div>
                                                <div className="icon btn btn-circle btn-lg btn-soft-navy pe-none mx-auto me-4 mb-lg-3 mb-xl-0"> <i className="uil uil-user-check"></i> </div>
                                            </div>
                                            <div>
                                                <h3 className="counter mb-1" style={{ visibility: "visible" }}>3500+</h3>
                                                <p className="mb-0">People Trained Globally</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-row align-items-center">
                                            <div>
                                                <div className="icon btn btn-circle btn-lg btn-soft-navy pe-none mx-auto me-4 mb-lg-3 mb-xl-0"> <i className="uil uil-users-alt"></i> </div>
                                            </div>
                                            <div>
                                                <h3 className="counter mb-1" style={{ visibility: "visible" }}>10,000+</h3>
                                                <p className="mb-0">People Reached Globally</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex flex-row align-items-center">
                                            <div>
                                                <div className="icon btn btn-circle btn-lg btn-soft-navy pe-none mx-auto me-4 mb-lg-3 mb-xl-0"> <i className="uil uil-trophy"></i> </div>
                                            </div>
                                            <div>
                                                <h3 className="counter mb-1" style={{ visibility: "visible" }}>4+</h3>
                                                <p className="mb-0">Educational Programs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 offset-lg-0">
                        <h2 className="display-6 mb-4">Our History and Background</h2>
                        <div className="row gx-0">
                            <div className="col-md-9 text-justify">
                                <p>Codevixens Limited was founded in 2020 by Lois Bassey, a seasoned software engineer and staunch advocate for female inclusion in the tech industry. The academy emerged amidst the global challenges of the COVID-19 pandemic, born out of Lois's determination to empower women in the tech field. Initially considering the name "The LadyDevs Mentorship Program," Lois ultimately chose "Codevixens" to reflect the bold and fierce spirit of women breaking into the male-dominated tech industry. Despite the initial challenges of building the academy, Codevixens was successfully formed. This effort paid off, and the academy has since grown to include thousands of participants worldwide. Our target audience as a tech educational organisaton has expanded from solely females to include males as well, making the programs inclusive for all.</p>
                                <p>Although we were initially established as a non-profit, Codevixens has transitioned to a sustainable financial model to ensure the long-term viability of providing high-quality tech education at affordable prices. This commitment to affordability is further strengthened by our partnerships with organizations and generous individuals who offer scholarships to subsidize tuition fees and open our programs to a wider audience.</p>
                                <div className="mt-4">
                                    <h2 className="display-6 mb-2">Our Vision</h2>
                                    <p className="mb-4">"<i>To pioneer a global movement in tech education, enabling individuals from diverse backgrounds to learn, enter, and excel in tech careers</i>". Our mission is to guide them through the learning process, facilitate their entry into the tech industry, and support their journey towards lasting success. </p>
                                </div>
                                <div className="mt-4">
                                    <h2 className="display-6 mb-2">Our Mission</h2>
                                    <p className="mb-0">"<i><strong className="text-navy">To empower 20,000 individuals worldwide</strong> through affordable and top-tier tech education <strong className="text-navy">within the next two years</strong></i>". While our primary focus is on women in tech, we welcome all genders to join our educational programs, fostering gender inclusivity in the tech industry.</p>
                                </div>
                            </div>
                            <div className="col-md-2 ms-auto mt-5 mt-lg-0">
                                <ul className="list-unstyled">
                                    <li>
                                        <h5 className="mb-1">Date Founded</h5>
                                        <p>August 2020</p>
                                    </li>
                                    <li>
                                        <h5 className="mb-1">Founder Name</h5>
                                        <p>Lois Bassey</p>
                                    </li>
                                </ul>
                                {/* <a href="#" className="more hover">Read up more about Lois</a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 gx-md-6 gy-6">
                    <div className="item col-md-4">
                        <figure className="hover-scale rounded cursor-dark"><a href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603852/website/IMG_1239_lfafot.jpg" data-glightbox="" data-gallery="project-1"><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603852/website/IMG_1239_lfafot.jpg" alt="" /></a></figure>
                    </div>
                    <div className="item col-md-4">
                        <figure className="hover-scale rounded cursor-dark"><a href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603200/website/stem-a-girl-main_suaon4.jpg" data-glightbox="" data-gallery="project-1"><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603200/website/stem-a-girl-main_suaon4.jpg" alt="" /></a></figure>
                    </div>
                    <div className="item col-md-4">
                        <figure className="hover-scale rounded cursor-dark"><a href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697616275/website/IMG_1120_pmspfo.jpg" data-glightbox="" data-gallery="project-1"><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697616275/website/IMG_1120_pmspfo.jpg" alt="" /></a></figure>
                    </div>
                    <div className="item col-md-4">
                        <figure className="hover-scale rounded cursor-dark"><a href="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603187/website/home-page_h07lpq.jpg" data-glightbox="" data-gallery="project-1"><img src="https://res.cloudinary.com/dar3e312jder/image/upload/v1697603187/website/home-page_h07lpq.jpg" alt="" /></a></figure>
                    </div>
                </div>
                <div className="row mt-8 mt-md-12">
                    <div className="col-md-12 offset-md-0 text-justify">
                        <h2 className="mb-4">Our Core Values</h2>
                        <p><strong>Empowerment:</strong> We believe in the transformative power of education to uplift individuals and provide them with the skills and opportunities needed to succeed in the tech industry. Our mission to empower 20,000 individuals worldwide reflects our commitment to fostering personal and professional growth through accessible and top-tier tech education.</p>
                        <p><strong>Inclusivity:</strong> We are dedicated to creating an inclusive learning environment where individuals from all backgrounds, regardless of gender or ethnicity, feel welcomed and supported in pursuing their tech education and career goals. Our focus on fostering gender inclusivity in the tech industry exemplifies our commitment to diversity and equal opportunities for all.</p>
                        <p><strong>Excellence:</strong> We strive for excellence in all aspects of our work, from the quality of our educational programs to the support and guidance we provide to our students. Our vision to pioneer a global movement in tech education underscores our relentless pursuit of innovation and leadership in the field, setting the highest standards for ourselves and our students.</p>
                        <p><strong>Community:</strong> We value collaboration, support, and camaraderie within our Codevixens community. Our mission to guide individuals through the learning process and facilitate their entry into the tech industry emphasizes our belief in the power of community to drive collective success and create lasting impact.</p>
                        <p><strong>Student-Centricity:</strong> Our ultimate satisfaction lies in the success and fulfillment of our students. We are dedicated to providing personalized guidance, mentorship, and support to help each student achieve their dreams and carve out prosperous career paths in the tech industry. Our unwavering focus on the needs and aspirations of our students guides everything we do at Codevixens.</p>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default WhoWeAre;
