const CareerOutlook = () => {
    return (
        <section className="py-4">
            <h2 className="text-line mb-1 text-navy lead fs-md fw-bold">Career Outlook: Why Database Administration is a High-Demand Field</h2>
            <p>In today’s data-driven world, the role of a <b>Database Administrator (DBA)</b> has become more critical than ever. As businesses continue to leverage vast amounts of data for decision-making, the demand for skilled database professionals has skyrocketed.</p>
            <p className="fw-bold">Career Paths in Database Administration</p>
            <p>With expertise in database management, you can pursue several rewarding career paths, including:</p>
            <ul className="icon-list bullet-bg bullet-soft-navy mb-5">
                <li><span><i className="uil uil-check"></i></span><span>Database Administrator</span></li>
                <li className="mt-2"><span><i className="uil uil-check"></i></span><span>Data Engineer</span></li>
                <li className="mt-2"><span><i className="uil uil-check"></i></span><span>Data Architect</span></li>
                <li className="mt-2"><span><i className="uil uil-check"></i></span><span>Business Intelligence Analyst</span></li>
            </ul>
            <p className="fw-bold">The Job Market for Database Administrators</p>
            <p>The global database administration job market is expected to grow by over 10% in the next decade as more organizations depend on data and cloud technologies.</p>
            <p>Salary Range: DBAs earn an average salary ranging from $70,000 to $120,000 annually, with more experienced professionals earning even more.</p>
            
        </section>
    );
};

export default CareerOutlook;