import React, {useState} from 'react';
import { job_details } from './jobs';
import { Link } from 'react-router-dom';

const Positions = () => {
    return (
        <section className="wrapper bg-light">
            <div className="container  pb-10">
                <div className="row text-center">
                    <div className="col-xl-10 mx-auto">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Job Positions</h2>
                        <h3 className="display-5 mb-10 px-xxl-15">We’re always searching for amazing people to join our team. Take a look at our current openings.</h3>
                    </div>
                </div>

                <div className='d-flex pb-6 gap-2'>
                    <select 
                        aria-label='Filter By Job Position'
                        className='form-select'
                    >
                        <option value="job position" >Job Position</option>
                        <option value="fe" >Frontend Developer</option>
                        <option value="be" >Backend Developer</option>
                        <option value="ux" >UI/UX Designer</option>
                        <option value="gd" >Graphic Designer</option>
                    </select>
                    <select 
                        aria-label='Filter By Job Type'
                        className='form-select'
                    >
                        <option value="Job Type" >Job Type</option>
                        <option value="intern" >Intern</option>
                        <option value="entry" >Entry</option>
                        <option value="mid-level" >Mid Level</option>
                        <option value="senior" >Senior</option>
                    </select>

                    <button className='btn btn-dark rounded'>Search</button>
                </div>
                <div className="card shadow-lg lift h-100">
                    <div className="card-body p-5 d-flex flex-row align-items-center justify-content-center">
                        <span className='text-grey fs-16 p-0 m-0'> No available positions now ..</span>
                    </div>
                </div>
                
                {/* <div className="row gy-6">
                {job_details.map((job) =>(
                <div className="col-md-6 col-lg-4" key={job.id}>
                        <Link  key={job.id} to={`/open-positions/${job.id}`} className="card shadow-lg lift h-100">
                            
                                <div className="card-body p-5 d-flex flex-row">
                                    <div>
                                        <span className="avatar bg-dark text-white w-11 h-11 fs-20 me-4">{job.pic}</span>
                                    </div>
                                    <div>
                                        <span className="badge bg-primary text-white rounded py-1 mb-2">{job.category}</span>
                                        <h4 className="mb-1">{job.position}</h4>
                                        <span className="badge bg-pale-navy text-navy rounded py-1 mb-2">{job.type}</span>
                                    </div>
                                </div>
                        </Link>
                    </div>
                     ))}            
                </div> */}
            </div>
        </section>
    );
};

export default Positions;
