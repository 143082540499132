const FooterCTA = () => {
    return (
        <section className="wrapper">
                <div className="container py-7 py-md-7">
                    <div className="card bg-full mb-7 bg-dark">
                        <div className="card-body p-10 p-xl-12">
                            <div className="row text-center">
                                <div className="col-xl-11 col-xxl-10 mx-auto">
                                    <h2 className="fs-16 text-uppercase text-white mb-3">Further Your Tech Journey With Us</h2>
                                    <h3 className="display-3 mb-8 px-lg-8 text-white">Don’t miss the chance to <span className="underline-3 style-2 yellow">boost</span> your career with our 7-week Academy Program.</h3>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <span><a className="btn btn-white rounded">Apply Now</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}
export default FooterCTA;
